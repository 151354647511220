import { __, sprintf, _n } from '@wordpress/i18n';
import { useEffect, useState } from '@wordpress/element';

const MultiSelectBox = ( {
	boxId,
	options,
	settingsData,
	setSettings,
	excludedOption,
} ) => {
	const [ show, setShow ] = useState( false );
	const { permission: permissionSettings } = settingsData;
	const [ select, setSelected ] = useState( [
		...permissionSettings[ boxId ],
	] );

	const handleSelectBox = ( key ) => {
		if ( ! select?.includes( key ) ) {
			setSelected( [ ...select, key ] );
		} else {
			const selectedList = select?.filter( ( role ) => role !== key );
			setSelected( [ ...selectedList ] );
		}
	};

	// if ( options.hasOwnProperty( 'administrator' ) ) {
	// 	options = { ...options };
	// 	delete options?.administrator;
	// }

	useEffect( () => {
		setSettings( {
			...settingsData,
			permission: { ...permissionSettings, [ boxId ]: select },
		} );
	}, [ select ] );

	useEffect( () => {
		if ( excludedOption?.boxId === boxId ) {
			setSelected( [
				...select?.filter( ( role ) => role !== excludedOption?.role ),
			] );

			setSettings( {
				...settingsData,
				permission: {
					...permissionSettings,
					[ boxId ]: [
						...permissionSettings[ boxId ]?.filter(
							( permissionRole ) =>
								permissionRole !== excludedOption?.role
						),
					],
				},
			} );
		}
	}, [ excludedOption ] );

	useEffect( () => {
		const element = document.querySelector( `#${ boxId }` );

		document.addEventListener( 'click', function ( event ) {
			if ( ! element?.contains( event.target ) ) {
				setShow( false );
			}
		} );
	}, [ show ] );

	return (
		<div id={ boxId } className="multiSelectBox">
			<div className="relative mb-2">
				<button
					onClick={ () => setShow( ! show ) }
					className="w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
				>
					{ select?.length > 0 ? (
						<span className="block multiSelectBox truncate">
							{ sprintf(
								_n(
									__( '%s role selected', 'wedocs-pro' ),
									__( '%s roles selected', 'wedocs-pro' ),
									select?.length
								),
								select?.length
							) }
						</span>
					) : (
						<span className="block multiSelectBox truncate">
							{ __( '1 role selected', 'wedocs-pro' ) }
						</span>
					) }
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
							className="h-5 w-5 text-gray-400"
						>
							<path
								d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
								clipRule="evenodd"
							></path>
						</svg>
					</span>
				</button>
			</div>

			{ show && options && (
				<div className="relative" onClick={ () => setShow( true ) }>
					<ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{ Object.entries( options ).map( ( option ) => (
							<li
								key={ option[ 0 ] }
								className={ `${ option[ 0 ] === 'administrator' ? 'bg-gray-200 hover:bg-gray-300' : 'hover:bg-[#F9FAFB]' } cursor-pointer text-gray-900 flex items-center select-none py-2 px-4` }
								onClick={ () => option[ 0 ] !== 'administrator' ? handleSelectBox( option[ 0 ] ) : '' }
							>
								<input
									type="checkbox"
									defaultChecked={ option[ 0 ] !== 'administrator' ?
										select?.includes( option[ 0 ] ) :
										true
									}
									style={ {
										boxShadow: 'none',
										borderColor: '#8c8f94',
										backgroundImage: 'none',
									} }
									className={ `${
										( option[ 0 ] === 'administrator' || select?.includes( option[ 0 ] ) )
											? `${ option[ 0 ] === 'administrator' ? '!bg-indigo-400 !border-indigo-400' : '!bg-indigo-700 !border-indigo-700' } before:!content-white-checked before:block before:!-mt-[1px] checked:before:!ml-0 checked:before:items-center checked:before:flex checked:before:justify-center checked:before:!w-full`
											: '!bg-gray-100 !border-gray-300 before:!content-none checked:bg-none'
									} w-4 h-4 !mt-0 !rounded-full` }
								/>
								<label
									htmlFor="multi-select"
									className="pl-2.5 font-normal block truncate d-block w-full"
								>
									{ sprintf(
										__( '%s', 'wedocs-pro' ),
										option[ 1 ]
									) }
								</label>
							</li>
						) ) }
					</ul>
				</div>
			) }
		</div>
	);
};

export default MultiSelectBox;
