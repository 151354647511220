import { __ } from '@wordpress/i18n';
import DraggableTabs from './DraggableTabs';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { useEffect, useState } from '@wordpress/element';
import Tabs from './Tabs';
import MessagePreview from '../MessagePreview'

const PlacementSettings = ( { settingsData, setSettings } ) => {
  let tabPanels = [
    { id: 1, key: 'integrate_ai', text: __( 'A.I. Chat', 'wedocs-pro' ) },
    { id: 2, key: 'explore', text: __( 'Explore', 'wedocs-pro' ) },
    { id: 3, key: 'message', text: __( 'Messaging', 'wedocs-pro' ) },
  ];

  const [ tabs, setTabs ] = useState( [ ...tabPanels ] );

  useEffect( () => {
    if ( settingsData?.assistant?.placement?.order?.length > 0 ) {
      const updatedPanels = tabPanels?.sort( ( a, b ) => {
        return settingsData?.assistant?.placement?.order?.indexOf( a?.key ) - settingsData?.assistant?.placement?.order?.indexOf( b?.key );
      } )
      setTabs( [ ...updatedPanels ] );
    }
  }, [ settingsData?.assistant?.placement?.order ] );

  const previewColors = settingsData?.assistant?.preference?.color_settings?.preview_colors

	return (
		<div className={ `placement-settings` }>
			<div className={ `placement-label` }>
				<div className={ 'flex items-center' }>
					<h3 className={ `text-base text-[#111827] font-medium leading-5` }>
						{ __( 'Drag your tabs and place orders', 'wedocs_pro' ) }
					</h3>
					<div
            className="tooltip cursor-pointer ml-2 z-[99999]"
            data-tip={ __(
              'Customize tab order by dragging and dropping to suit your preference',
              'wedocs-pro'
            ) }
          >
						<svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
            >
							<path
                d="M9.833 12.333H9V9h-.833M9 5.667h.008M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 1 1 15 0z"
                stroke="#6b7280"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
						</svg>
					</div>
				</div>
				<hr className="h-px !bg-gray-200 border-0 dark:!bg-gray-200 mt-5 mb-6" />
			</div>

			<div className="tab-order-settings relative flex justify-between items-start gap-4">
        <div className="flex items-center gap-4 flex-grow">
          <DraggableTabs setItems={ setTabs } settingsData={ settingsData } setSettings={ setSettings }>
            <SortableContext
              items={ tabs }
              strategy={ rectSortingStrategy }
            >
              { tabs?.map( ( tab ) => (
                <Tabs key={ tab?.key } tab={ tab } />
              ) ) }
            </SortableContext>
          </DraggableTabs>
        </div>
        <div className="mr-8 h-full">
          <MessagePreview
            classes={ 'w-96' }
            settings={ settingsData?.assistant }
            staticPreview={ true }
            previewColors={ previewColors }
          />
        </div>
      </div>
		</div>
  );
}

export default PlacementSettings;
