import { __ } from '@wordpress/i18n';
import { useEffect, useRef, useState } from '@wordpress/element';
import extractedTitle from '../../../utils/extractedTitle';
import { useSelect } from '@wordpress/data';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link, useParams } from 'react-router-dom';
import SingleDoc from '../../Frontend/SingleDoc';
import MessageFields from './MessageFields';
import { AiChat } from './AiChat'
import WidgetContentPopup from '../../WidgetLink/WidgetContentPopup';

const MessageBody = ( {
    docId,
    classes,
    settings,
    previewColors,
    showDocContent,
    frontendPreview,
    setShowDocContent,
} ) => {
	const { id } = useParams();

	const dummyDocs = [
		__( 'How to Install', 'wedocs-pro' ),
		__( 'Activating & Deactivating WPUF Modules', 'wedocs-pro' ),
	];

	const helpfulDocs = useSelect( ( select ) =>
		select( 'wedocs/docs' ).getHelpfulDocs(), []
	);

	const [ docs, setDocs ] = useState( [ ...helpfulDocs ] );

	const {
		widgetBg,
		activeTabBg,
		activeTabFont,
		inactiveTabBg,
		inactiveTabFont,
		tabTitleFont,
		tabDescriptionFont,
		breadcrumbColor,
	} = previewColors;

	const tabStyleRef = useRef( {
			color: `rgba(${ inactiveTabFont?.r }, ${ inactiveTabFont?.g }, ${ inactiveTabFont?.b }, ${ inactiveTabFont?.a })`,
		} ),
		activeTabStyleRef = useRef( {
			color: `rgba(${ activeTabFont?.r }, ${ activeTabFont?.g }, ${ activeTabFont?.b }, ${ activeTabFont?.a })`,
			background: `rgba(${ activeTabBg?.r }, ${ activeTabBg?.g }, ${ activeTabBg?.b }, ${ activeTabBg?.a })`,
		} );

	const tabSvgStyleRef = useRef( {
			fill: `rgba(${ inactiveTabFont?.r }, ${ inactiveTabFont?.g }, ${ inactiveTabFont?.b }, ${ inactiveTabFont?.a })`,
		} ),
		activeTabSvgStyleRef = useRef( {
			fill: `rgba(${ activeTabFont?.r }, ${ activeTabFont?.g }, ${ activeTabFont?.b }, ${ activeTabFont?.a })`,
		} );

	const [ selectedTab, setSelectedTab ] = useState( settings?.placement?.order?.[0] || 'explore' );

	let defaultTabs = [
		{
			key: 'integrate_ai',
			name: __( 'A.I. chat', 'wedocs-pro' ),
			enable: settings?.integrate_ai?.ai_enabled !== 'off',
		},
		{
			key: 'explore',
			name: __( 'Explore', 'wedocs-pro' ),
			enable: settings?.explore?.explore_enable !== 'off',
		},
		{
			key: 'message',
			name: __( 'Messaging', 'wedocs-pro' ),
			enable: settings?.message?.messaging_enable !== 'off',
		},
	];

	const [ tabs, setTabs ] = useState( [ ...defaultTabs ] );

	useEffect( () => {
		if ( selectedTab === 'integrate_ai' && settings?.integrate_ai?.ai_enabled === 'off' ) {
			if ( settings?.explore?.explore_enable === 'on' ) {
				setSelectedTab( 'explore' );
			} else if ( settings?.message?.messaging_enable === 'on' ) {
				setSelectedTab( 'message' );
			}
		}

		if ( selectedTab === 'message' && settings?.message?.messaging_enable === 'off' ) {
			if ( settings?.integrate_ai?.ai_enabled === 'on' ) {
				setSelectedTab( 'integrate_ai' );
			} else if ( settings?.explore?.explore_enable === 'on' ) {
				setSelectedTab( 'explore' );
			}
		}

		if ( selectedTab === 'explore' && settings?.explore?.explore_enable === 'off' ) {
			if ( settings?.integrate_ai?.ai_enabled === 'on' ) {
				setSelectedTab( 'integrate_ai' );
			} else if ( settings?.message?.messaging_enable === 'on' ) {
				setSelectedTab( 'message' );
			}
		}
	}, [ settings ] );

	useEffect( () => {
		if ( settings?.placement?.order?.length > 0 ) {
			const updatedTabsOrder = tabs?.sort( ( a, b ) => {
				return settings?.placement?.order?.indexOf( a?.key ) - settings?.placement?.order?.indexOf( b?.key );
			} );
			setTabs( [ ...updatedTabsOrder ] );
		}
	}, [ settings?.placement?.order ] )

	const handleTabSelection = ( tab ) => {
		setSelectedTab( tab );
	};

	useEffect( () => {
		const updatedActiveTabBgStyle = {
			...activeTabStyleRef.current,
			background: `rgba(${ activeTabBg?.r }, ${ activeTabBg?.g }, ${ activeTabBg?.b }, ${ activeTabBg?.a })`,
		};
		activeTabStyleRef.current = updatedActiveTabBgStyle;
	}, [ activeTabBg ] );

	useEffect( () => {
		const updatedActiveTabFontStyle = {
				...activeTabStyleRef.current,
				color: `rgba(${ activeTabFont?.r }, ${ activeTabFont?.g }, ${ activeTabFont?.b }, ${ activeTabFont?.a })`,
			},
			updatedActiveTabSvgStyle = {
				...activeTabSvgStyleRef.current,
				fill: `rgba(${ activeTabFont?.r }, ${ activeTabFont?.g }, ${ activeTabFont?.b }, ${ activeTabFont?.a })`,
			};

		activeTabStyleRef.current = updatedActiveTabFontStyle;
		activeTabSvgStyleRef.current = updatedActiveTabSvgStyle;
	}, [ activeTabFont ] );

	useEffect( () => {
		const updatedTabStyle = {
				...tabStyleRef.current,
				color: `rgba(${ inactiveTabFont?.r }, ${ inactiveTabFont?.g }, ${ inactiveTabFont?.b }, ${ inactiveTabFont?.a })`,
			},
			updatedTabSvgStyle = {
				...tabSvgStyleRef.current,
				fill: `rgba(${ inactiveTabFont?.r }, ${ inactiveTabFont?.g }, ${ inactiveTabFont?.b }, ${ inactiveTabFont?.a })`,
			};

		tabStyleRef.current = updatedTabStyle;
		tabSvgStyleRef.current = updatedTabSvgStyle;
	}, [ inactiveTabFont ] );

	const [ anchestors, setAnchestors ] = useState( [] );
	const [ searchValue, setSearchValue ] = useState( '' );

	const handleDocSearch = ( event, reset = false ) => {
		if ( reset ) {
			setSearchValue( '' );
		} else {
			setSearchValue( event.target.value );
		}
	};

	const getArticleAnchestors = ( docId, data ) => {
		const articleAnchestor = anchestors.find(
			( anchestor ) =>
				anchestor?.articleId === docId ||
				( anchestor?.sectionId === docId &&
					( data === 'documentationTitle' ||
						data === 'documentationRef' ) )
		);

		return articleAnchestor?.[ data ];
	};

	const allDocs = useSelect(
		( select ) => select( 'wedocs/docs' ).getDocs(),
		[]
	);

	const filteredDocs = ! Boolean( searchValue )
		? docs?.filter( ( doc ) =>
				doc?.title?.rendered
					?.toLowerCase()
					.includes( searchValue.toLowerCase() )
		  )
		: allDocs?.filter( ( doc ) =>
				doc?.title?.rendered
					?.toLowerCase()
					.includes( searchValue.toLowerCase() )
		  );

	const parentDocs = allDocs?.filter( ( doc ) => ! doc.parent );

	useEffect( () => {
		const anchestorsArray = [];

		parentDocs?.forEach( ( doc ) => {
			const sections = allDocs?.filter( ( d ) => d.parent === doc.id );

			sections.forEach( ( section ) => {
				allDocs?.forEach( ( d ) => {
					if ( d.parent === section.id ) {
						anchestorsArray.push( {
							articleId: d?.id,
							sectionId: section?.id,
							documentationId: doc?.id,
							documentationTitle: doc?.title?.rendered,
							documentationRef: doc?.link,
							sectionTitle: section?.title?.rendered,
							sectionRef: section?.link,
						} );
					}
				} );
			} );
		} );

		setAnchestors( [ ...anchestorsArray ] );
	}, [ allDocs ] );

	useEffect( () => {
		if ( 'selected' === settings?.explore?.show_content ) {
			setDocs( [ ...settings?.explore?.selected_articles ] );
		} else {
			if ( helpfulDocs?.length > 10 ) {
				setDocs( [ ...helpfulDocs ] );
				return;
			}

			const childrenDocs = allDocs?.filter( ( d ) => parseInt( d.parent ) !== 0 );
			const articles = childrenDocs?.filter( doc => childrenDocs?.find( cd => cd?.id === doc?.parent ) );
			if ( helpfulDocs?.length === 0 ) {
				setDocs( [ ...articles?.filter( ( doc, index ) => index < 10 ) ] );
				return;
			}

			const additionalDocs = [];
			const helpfulDocIds = helpfulDocs?.map( doc => doc?.id );
			const need_docs_count = ( 10 - helpfulDocs.length );
			articles?.filter( ( doc, index ) => {
				if ( additionalDocs.length >= need_docs_count ) return;
				!helpfulDocIds?.includes( doc?.id ) ? additionalDocs?.push( doc ) : '';
			} );

			setDocs( [ ...helpfulDocs, ...additionalDocs ] );
		}
	}, [ settings?.explore?.show_content, helpfulDocs, allDocs ] );

	return (
		<div
			className={ `${
				classes ? classes : ''
			} ${ frontendPreview ? 'w-[460px]' : 'w-[26rem]' } xs:w-[350px] palette-preview-container transition-all` }
		>
			<div
				className={ `${
					frontendPreview ? 'pb-5' : 'pb-4'
				} palette-preview bg-white rounded-[10px] shadow-xl mb-5 overflow-hidden` }
			>
				<div
					className="preview-header flex items-center justify-center min-h-48 xs:min-h-44"
					style={ {
						background: `rgba(${ widgetBg?.r }, ${ widgetBg?.g }, ${ widgetBg?.b }, ${ widgetBg?.a })`,
					} }
				>
					<div className="text-center">
						<div
							className="tabs flex items-center font-medium text-sm leading-5 w-fit h-10 xs:h-9 shadow-sm rounded-[10px] mb-5 mx-auto mt-[26px]"
							style={ {
								color: `rgba(${ inactiveTabFont?.r }, ${ inactiveTabFont?.g }, ${ inactiveTabFont?.b }, ${ inactiveTabFont?.a })`,
								background: `rgba(${ inactiveTabBg?.r }, ${ inactiveTabBg?.g }, ${ inactiveTabBg?.b }, ${ inactiveTabBg?.a })`,
							} }
						>
							{ tabs &&
								tabs?.map( ( tab ) => (
									<div
										key={ tab?.key }
										className={ `${
											! Boolean( tab?.enable )
												? 'hidden'
												: ''
										} tab flex items-center justify-center mx-auto gap-2 py-2.5 px-5 xs:px-3 rounded-[10px] cursor-pointer outline-none h-[100%] leading-4` }
										onClick={ () =>
											handleTabSelection( tab?.key )
										}
										style={
											selectedTab === tab?.key
												? activeTabStyleRef.current
												: tabStyleRef.current
										}
									>
										{ settings?.[ tab.key ]?.[ `${ tab.key }_tab_icon` ]
											?.src ? (
											<img
												src={
													settings?.[ tab.key ]?.[
														`${ tab.key }_tab_icon`
													]?.src
												}
												alt={
													settings?.[ tab.key ]?.[
														`${ tab.key }_tab_icon`
													]?.name
												}
												className="max-h-5 max-w-5"
											/>
										) : (
											// tab?.icon
											<>
												{ tab?.key === 'integrate_ai' && (
													<svg width="19" height="16" fill="none">
														<path
															fillRule="evenodd"
															style={
																selectedTab === tab?.key
																	? activeTabSvgStyleRef.current
																	: tabSvgStyleRef.current
															}
															d="M1.703.818a1.02 1.02 0 0 1 1.022 1.022 1.02 1.02 0 0 1-.606.934v3.032l.165-.017C3.033 2.635 5.979.818 9.5.818s6.466 1.816 7.216 4.97l.165.014V2.775a1.02 1.02 0 0 1-.606-.934c0-.564.458-1.022 1.022-1.022a1.02 1.02 0 0 1 1.022 1.022 1.02 1.02 0 0 1-.606.934v3.245c.544.249.911.702.949 1.405l.003.568-.001.768c-.057 1.063-.862 1.557-1.897 1.638-.65 3.305-3.658 5.219-7.269 5.219s-6.62-1.914-7.269-5.221C1.195 10.3.392 9.72.337 8.659l-.003-.666.003-.466a1.68 1.68 0 0 1 .949-1.472v-3.28a1.02 1.02 0 0 1-.605-.934c0-.564.458-1.022 1.022-1.022zm13.566 6.45l.009.951-.01.939c-.16 3.046-2.682 4.837-5.768 4.837s-5.607-1.79-5.768-4.837l-.008-.939.009-.951c.166-3.042 2.685-4.826 5.768-4.826s5.601 1.784 5.768 4.826zM13.17 7.17c0-.602-.486-1.088-1.088-1.088s-1.088.486-1.088 1.088.486 1.088 1.088 1.088 1.088-.486 1.088-1.088zm-5.167 0a1.09 1.09 0 0 1-1.088 1.088A1.09 1.09 0 0 1 5.827 7.17a1.09 1.09 0 0 1 1.088-1.088A1.09 1.09 0 0 1 8.003 7.17z"
														/>
													</svg>
												) }

												{ tab?.key === 'explore' && (
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="14"
														height="18"
														style={
															selectedTab === tab?.key
																? activeTabSvgStyleRef.current
																: tabSvgStyleRef.current
														}
													>
														<path d="M1.161 2.514a2 2 0 0 1 2-2h4.586a2 2 0 0 1 1.414.586l3.414 3.414a2 2 0 0 1 .586 1.414v8.586a2 2 0 0 1-2 2H9.633c.95-1.062 1.528-2.463 1.528-4a6 6 0 0 0-6-6c-1.537 0-2.938.578-4 1.528V2.514z" />
														<path
															fillRule="evenodd"
															d="M5.161 8.514a4 4 0 0 0-4 4c0 .741.202 1.436.554 2.032L.454 15.807a1 1 0 0 0 0 1.414 1 1 0 0 0 1.414 0l1.261-1.261c.595.352 1.29.554 2.032.554a4 4 0 1 0 0-8zm-2 4a2 2 0 1 1 4 0 2 2 0 0 1-2 2 1.99 1.99 0 0 1-1.414-.586 1.99 1.99 0 0 1-.586-1.414z"
														/>
													</svg>
												) }

												{ tab?.key === 'message' && (
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="17"
														height="15"
														style={
															selectedTab === tab?.key
																? activeTabSvgStyleRef.current
																: tabSvgStyleRef.current
														}
													>
														<path
															fillRule="evenodd"
															d="M16.161 7.514c0 3.866-3.582 7-8 7-1.492 0-2.888-.357-4.083-.979l-3.917.979 1.338-3.123C.654 10.281.161 8.948.161 7.514c0-3.866 3.582-7 8-7s8 3.134 8 7zm-11-1h-2v2h2v-2zm8 0h-2v2h2v-2zm-6 0h2v2h-2v-2z"
														/>
													</svg>
												) }
											</>
										) }
										{ settings?.[ tab.key ]?.[ `${ tab?.key }_title` ]
											? extractedTitle(
													settings?.[ tab.key ]?.[
														`${ tab?.key }_title`
													],
													10
											  )
											: __( tab?.name, 'wedocs-pro' ) }
									</div>
								) ) }
						</div>
						<div
							className={ `preview-heading xs:mb-0.5 mb-1.5 ${
								settings?.preference?.widget_title_font?.size
									? `text-${ settings?.preference?.widget_title_font?.size }`
									: 'text-lg'
							} ${
								settings?.preference?.widget_title_font?.weight
									? `font-${ settings?.preference?.widget_title_font?.weight }`
									: 'font-medium'
							}` }
							style={ {
								color: `rgba(${ tabTitleFont?.r }, ${ tabTitleFont?.g }, ${ tabTitleFont?.b }, ${ tabTitleFont?.a })`,
							} }
						>
							{ settings?.[ selectedTab ]?.[ `${ selectedTab }_subtitle_one` ]
								? extractedTitle(
										settings?.[ selectedTab ]?.[
											`${ selectedTab }_subtitle_one`
										],
										20
								  )
								: ( selectedTab === 'explore' ? __(  'Explore Feature', 'wedocs-pro' ) :
									( selectedTab === 'message' ? __( 'Message Us', 'wedocs-pro' ) :
										__( 'A.I. Chat', 'wedocs-pro' ) ) ) }
						</div>
						<div
							className={ `preview-description w-[350px] mx-auto mb-[30px] ${
								settings?.preference?.widget_description_font?.size
									? `text-${ settings?.preference?.widget_description_font?.size }`
									: 'text-base'
							} ${
								settings?.preference?.widget_description_font?.weight
									? `font-${ settings?.preference?.widget_description_font?.weight }`
									: 'font-normal'
							}` }
							style={ {
								color: `rgba(${ tabDescriptionFont?.r }, ${ tabDescriptionFont?.g }, ${ tabDescriptionFont?.b }, ${ tabDescriptionFont?.a })`,
							} }
						>
							{ settings?.[ selectedTab ]?.[ `${ selectedTab }_subtitle_two` ]
								? extractedTitle(
										settings?.[ selectedTab ]?.[
											`${ selectedTab }_subtitle_two`
										],
										80
								  )
								: ( selectedTab === 'explore' ? __( 'Search with keywords for quick answers', 'wedocs-pro' ) :
									( selectedTab === 'message' ? __( 'Feel free to get in touch for any inquiries', 'wedocs-pro' ) :
										__( 'Feel free to ask any questions or inquiries', 'wedocs-pro' ) ) ) }
						</div>
					</div>
				</div>

				<div
					className={ `${
						selectedTab !== 'explore' && 'hidden'
					} explore-tab -mt-5` }
				>
					<div
						className={ `${
							frontendPreview && Boolean( id ) && 'hidden'
						} listing-docs` }
					>
						<div
							className={ `${
								frontendPreview
									? '!py-[9px] !px-3.5'
									: 'px-3 py-1'
							} search-panel xs:!py-[7px] doc-search-panel relative flex items-center justify-center border border-solid border-[#D1D5DB] bg-white rounded-md w-80 xxs:w-72 mx-auto shadow-sm mb-8 xs:mb-4` }
						>
							<input
								id="doc-search"
								type="text"
								className="!border-0 !border-transparent !bg-white text-sm text-gray-900 placeholder-gray-400 w-full focus:!shadow-none !px-0 !py-0 disabled:shadow-none"
								placeholder={ __( 'Search', 'wedocs-pro' ) }
								value={ searchValue }
								disabled={ ! frontendPreview }
								onChange={ handleDocSearch }
							/>
							{ searchValue && searchValue.length > 0 && (
								<div
									onClick={ ( e ) =>
										handleDocSearch( e, true )
									}
									className="cursor-pointer absolute inset-y-0 right-9 flex items-center pr-2"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={ 1.5 }
										className="w-5 h-5 stroke-[#6b7280]"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M6 18L18 6M6 6l12 12"
										/>
									</svg>
								</div>
							) }
							<label htmlFor="doc-search">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									fill="none"
									className="cursor-pointer"
								>
									<path
										d="M16.661 16.094l-5-5m1.667-4.167c0 3.222-2.612 5.833-5.833 5.833s-5.833-2.612-5.833-5.833 2.612-5.833 5.833-5.833 5.833 2.612 5.833 5.833z"
										stroke="#6b7280"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</label>
						</div>

						<div
							className={ `${
								frontendPreview
									? 'h-80 overflow-y-auto'
									: 'h-80'
							} xs:h-56 xs:px-4 explore-docs` }
						>
							{ frontendPreview ? (
								<>
									{ filteredDocs?.length > 0 ? (
										filteredDocs?.map( ( doc, index ) => (
											<div
												key={ index }
												className={ `doc-section border border-solid border-[#D1D5DB] box-border p-4 mb-2.5 last:mb-0 rounded-md w-[390px] xs:w-full mx-auto` }
											>
												<nav
													aria-label="Breadcrumb"
													className="breadcrumbs mb-2 flex group-hover:block"
												>
													<ol
														role="list"
														className="flex items-center list-none m-0 p-0"
													>
														<li className="m-0">
															<div className="flex items-center">
																<a
																	target="_blank"
																	href={ getArticleAnchestors(
																		doc?.id,
																		'documentationRef'
																	) }
																	style={ {
																		color: `rgba(${ breadcrumbColor?.r }, ${ breadcrumbColor?.g }, ${ breadcrumbColor?.b }, ${ breadcrumbColor?.a })`,
																	} }
																	className={ `text-sm leading-5 cursor-pointer no-underline hover:underline hover:decoration-[rgba(${ breadcrumbColor?.r }, ${ breadcrumbColor?.g }, ${ breadcrumbColor?.b }, ${ breadcrumbColor?.a })]` }
																	rel="noreferrer"
																	dangerouslySetInnerHTML={ {
																		__html: extractedTitle(
																			getArticleAnchestors(
																				doc?.id,
																				'documentationTitle'
																			),
																			20
																		),
																	} }
																></a>
																<ChevronRightIcon
																	className={ `${
																		( doc?.parent ===
																			0 ||
																			! Boolean(
																				getArticleAnchestors(
																					doc?.id,
																					'sectionTitle'
																				)
																			) ) &&
																		'hidden'
																	} h-4 w-4 mx-1 flex-shrink-0` }
																	style={ {
																		color: `rgba(${ breadcrumbColor?.r }, ${ breadcrumbColor?.g }, ${ breadcrumbColor?.b }, ${ breadcrumbColor?.a })`,
																	} }
																	aria-hidden="true"
																/>
															</div>
														</li>
														<li className="m-0">
															<div className="flex items-center">
																<a
																	target="_blank"
																	href={ getArticleAnchestors(
																		doc?.id,
																		'sectionRef'
																	) }
																	style={ {
																		color: `rgba(${ breadcrumbColor?.r }, ${ breadcrumbColor?.g }, ${ breadcrumbColor?.b }, ${ breadcrumbColor?.a })`,
																	} }
																	className={ `text-sm leading-5 cursor-pointer hover:underline no-underline hover:decoration-[rgba(${ breadcrumbColor?.r }, ${ breadcrumbColor?.g }, ${ breadcrumbColor?.b }, ${ breadcrumbColor?.a })]` }
																	rel="noreferrer"
																	dangerouslySetInnerHTML={ {
																		__html: extractedTitle(
																			getArticleAnchestors(
																				doc?.id,
																				'sectionTitle'
																			),
																			20
																		),
																	} }
																></a>
															</div>
														</li>
													</ol>
												</nav>
												<div className="doc-heading mb-1.5 hover:underline">
													<Link
														to={ `${ window.location.origin }/${ doc?.id }` }
														className={ `text-lg text-gray-900 no-underline` }
														dangerouslySetInnerHTML={ {
															__html: extractedTitle(
																doc?.title
																	?.rendered,
																32
															),
														} }
													></Link>
												</div>
												<div
													className="doc-description text-sm font-normal text-gray-500 leading-5 break-words"
													dangerouslySetInnerHTML={ {
														__html: extractedTitle(
															doc?.content
																?.rendered,
															100
														),
													} }
												></div>
											</div>
										)
									) ) : (
										<div className="flex items-center cursor-pointer text-base px-8 w-[340px] xs:w-full xs:px-2">
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
												<path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path>
											</svg>
											{ ( searchValue && searchValue?.length > 0 ? __( 'Search result', 'wedocs-pro' ) : __( 'Data', 'wedocs-pro' ) ) +  __( ' not found', 'wedocs-pro' ) }
										</div>
									) }
								</>
							) : (
								<>
									{ dummyDocs &&
										dummyDocs?.map( ( docTitle, index ) => (
											<div
												key={ index }
												className={ `${
													selectedTab !== 'explore' &&
													'hidden'
												} doc-section border border-[#D1D5DB] box-border p-4 mb-2.5 last:mb-0 rounded-md w-[362px] mx-auto` }
											>
												<div
													className="breadcrumbs text-sm mb-2 p-0"
													style={ {
														color: `rgba(${ breadcrumbColor?.r }, ${ breadcrumbColor?.g }, ${ breadcrumbColor?.b }, ${ breadcrumbColor?.a })`,
													} }
												>
													{ __(
														'WP User Frontend… > Getting Started',
														'wedocs-pro'
													) }
												</div>
												<div className="doc-heading text-lg text-gray-900 mb-1.5">
													{ docTitle }
												</div>
												<div className="doc-description text-sm font-normal text-gray-500 leading-5">
													{ __(
														'Here you will find everything about WP User Frontend, what it offers and its',
														'wedocs-pro'
													) }
												</div>
											</div>
										) ) }
								</>
							) }
						</div>
					</div>

					{ Boolean( id ) && <SingleDoc docId={ id } /> }
				</div>

				{ selectedTab === 'message' && (
					<MessageFields
						turnstileSiteKey={ settings?.message?.turnstile_site_key || '' }
						frontendPreview={ frontendPreview }
						previewColors={ previewColors }
					/>
				) }

				{ selectedTab === 'integrate_ai' && (
					<AiChat frontendPreview={ frontendPreview } />
				) }

				{ frontendPreview && showDocContent && (
					<WidgetContentPopup
						setShowDocContent={ setShowDocContent }
						doc={ allDocs?.find( doc => doc?.id === parseInt( docId ) ) }
					/>
				) }
			</div>
		</div>
	);
};

export default MessageBody;
