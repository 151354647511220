import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { __, sprintf } from '@wordpress/i18n';
import ArticleSelectionBox from '../../ArticleSelectionBox';
import { useEffect, useState } from '@wordpress/element';
import extractedTitle from '../../../../utils/extractedTitle';

const ExploreSettings = ( { settingsData, setSettings } ) => {

	const exploreBy = [
		{
			field: 'helpful',
			title: __( 'Helpful Content', 'wedocs-pro' ),
			description: __(
				'Top 10 contents that are found as most helpful by the visitors will be set automatically in descending order. If content is less or equals to 10, all of the 10 articles would be shown automatically and will be prioritized by helpfulness',
				'wedocs-pro'
			),
		},
		{
			field: 'selected',
			title: __( 'Selected Content', 'wedocs-pro' ),
			description: __(
				'Manually select content that will appear in the Explore tab of the Assistant widget',
				'wedocs-pro'
			),
		},
	];

	const [ selectedExploreBy, setSelectedExploreBy ] = useState(
		exploreBy[ 0 ]
	);

	const classNames = ( ...classes ) => {
		return classes.filter( Boolean ).join( ' ' );
	};

	const [ anchestors, setAnchestors ] = useState( [] );
	const [ selectedArticles, setSelectedArticles ] = useState( [
		...( settingsData?.assistant?.explore?.selected_articles || '' ),
	] );

	const getArticleAnchestors = ( articleId, data ) => {
		const articleAnchestor = anchestors.find(
			( anchestor ) => anchestor?.articleId === articleId
		);

		return articleAnchestor?.[ data ];
	};

	const removeSelectedArticle = ( articleId ) => {
		const articles = selectedArticles?.filter(
			( article ) => article?.id !== articleId
		);
		setSelectedArticles( [ ...articles ] );

		setSettings( {
			...settingsData,
			assistant: {
				...settingsData?.assistant,
				explore: {
					...settingsData?.assistant?.explore,
					selected_articles: [ ...articles ],
				}
			},
		} );
	};

	const handleExploreContentSettings = ( selectedObj ) => {
		setSelectedExploreBy( { ...selectedObj } );
		setSettings( {
			...settingsData,
			assistant: {
				...settingsData?.assistant,
				explore: {
					...settingsData?.assistant?.explore,
					show_content: selectedObj?.field,
				}
			},
		} );
	};

	useEffect( () => {
		if ( Boolean( settingsData?.assistant?.explore?.show_content ) ) {
			setSelectedExploreBy(
				exploreBy?.find(
					( explore ) =>
						explore?.field === settingsData?.assistant?.explore?.show_content
				)
			);
		}
	}, [ settingsData ] );

	useEffect( () => {
		setSettings( {
			...settingsData,
			assistant: {
				...settingsData?.assistant,
				explore: {
					...settingsData?.assistant?.explore,
					selected_articles: [ ...selectedArticles ],
				}
			},
		} );
	}, [ selectedArticles ] );

	return (
		<div className="explore-settings relative">
			<RadioGroup
				value={ selectedExploreBy }
				onChange={ handleExploreContentSettings }
			>
				<div className="my-5 grid grid-cols-3 !gap-6 sm:grid-cols-3 sm:gap-x-4">
					{ exploreBy.map( ( explore, index ) => (
						<RadioGroup.Option
							key={ index }
							value={ explore }
							className={ ( { checked, active } ) =>
								classNames(
									checked
										? 'border-transparent'
										: 'border-gray-300',
									active
										? 'border-indigo-600 ring-2 ring-indigo-600'
										: '',
									'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
								)
							}
						>
							<span className="flex">
								<span className="flex flex-col">
									<RadioGroup.Label
										as="span"
										className="flex items-center text-sm font-medium text-gray-600 mb-0.5"
									>
										{ explore.title }
										<div
											className={ classNames(
												selectedExploreBy?.field !==
												explore?.field
													? 'border border-gray-400'
													: '',
												'ml-auto rounded-full w-4 h-4'
											) }
										>
											<CheckCircleIcon
												className={ classNames(
													selectedExploreBy?.field !==
													explore?.field
														? 'invisible'
														: '-mt-0.5',
													'h-5 w-5 text-indigo-600'
												) }
												aria-hidden="true"
											/>
										</div>
									</RadioGroup.Label>
									<RadioGroup.Description
										as="span"
										className="mt-1 flex items-center font-medium text-xs !leading-5 text-[#6B7280]"
									>
										{ explore?.description }
									</RadioGroup.Description>
									<span
										className={ classNames(
											selectedExploreBy?.id ===
											explore?.id
												? 'border'
												: 'border-2',
											selectedExploreBy?.id ===
											explore?.id
												? 'border-indigo-600'
												: 'border-transparent',
											'pointer-events-none absolute -inset-px rounded-lg'
										) }
										aria-hidden="true"
									/>
								</span>
							</span>
						</RadioGroup.Option>
					) ) }
				</div>
			</RadioGroup>
			{ selectedExploreBy &&
				selectedExploreBy?.field === 'selected' && (
					<div className="grid grid-cols-4 gap-5 my-6">
						<div className="col-span-4">
							<div className="settings-content flex items-center justify-between">
								<div className="settings-field-heading md:min-w-[300px] flex items-center space-x-2 flex-1">
									<label
										className="block text-sm font-medium text-gray-600"
										id="headlessui-listbox-label-15"
										data-headlessui-state="open"
									>
										{ __(
											'Select Content',
											'wedocs-pro'
										) }
									</label>
									<div
										className="tooltip cursor-pointer ml-2"
										data-tip={ __(
											'Handpick content to showcase in the explore tab',
											'wedocs-pro'
										) }
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="18"
											height="18"
											fill="none"
										>
											<path
												d="M9.833 12.333H9V9h-.833M9 5.667h.008M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 1 1 15 0z"
												stroke="#6b7280"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</div>
								<div className="settings-field w-full max-w-[490px] ml-auto flex-2">
									<ArticleSelectionBox
										selected={ selectedArticles }
										anchestors={ anchestors }
										setSelected={ setSelectedArticles }
										setAnchestors={ setAnchestors }
									/>
								</div>
							</div>
							{ selectedArticles &&
								selectedArticles?.length > 0 && (
									<div className="selected-articles rounded-md mt-6 max-w-[490px] ml-auto border border-gray-300 bg-white text-left shadow-sm">
										<div className="w-full py-2 pl-6 pr-10 bg-gray-200 text-xs font-medium text-gray-500 tracking-wider">
											{ __(
												'Selected Articles',
												'wedocs-pro'
											) }
										</div>
										<div className="relative px-3">
											<ul className="z-10 my-1.5">
												{ selectedArticles?.map(
													( article ) => (
														<li
															key={
																article?.id
															}
															className="cursor-pointer text-gray-900 select-none !mb-0.5 group hover:rounded-md hover:border hover:border-[#E5E7EB] hover:py-1"
														>
															<nav
																className="hidden flex px-3.5 pt-2.5 group-hover:block"
																aria-label="Breadcrumb"
															>
																<ol
																	role="list"
																	className="flex items-center"
																>
																	<li className="m-0">
																		<div className="flex items-center">
																			<a
																				href={ getArticleAnchestors(
																					article?.id,
																					'documentationRef'
																				) }
																				className="text-xs leading-5 text-gray-500 hover:text-indigo-700 cursor-pointer"
																			>
																				{ extractedTitle(
																					getArticleAnchestors(
																						article?.id,
																						'documentationTitle'
																					),
																					22
																				) }
																			</a>
																			<ChevronRightIcon
																				className="h-3.5 w-3.5 mx-1 flex-shrink-0 text-gray-400"
																				aria-hidden="true"
																			/>
																		</div>
																	</li>
																	<li className="m-0">
																		<div className="flex items-center">
																			<a
																				href={ getArticleAnchestors(
																					article?.id,
																					'sectionRef'
																				) }
																				className="text-xs leading-5 text-gray-500 hover:text-indigo-700 cursor-pointer"
																			>
																				{ extractedTitle(
																					getArticleAnchestors(
																					article?.id,
																					'sectionTitle'
																					),
																					22
																				) }
																			</a>
																		</div>
																	</li>
																</ol>
															</nav>
															<label
																htmlFor="multi-select"
																className="py-2.5 px-3.5 font-normal block truncate flex items-center w-full group text-sm leading-5 text-gray-500"
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="14"
																	height="18"
																	fill="none"
																	className="mr-2.5"
																>
																	<path
																		d="M4.5 9h5m-5 3.333h5m1.667 4.167H2.833c-.92 0-1.667-.746-1.667-1.667V3.167c0-.92.746-1.667 1.667-1.667h4.655c.221 0 .433.088.589.244l4.512 4.512c.156.156.244.368.244.589v7.988c0 .921-.746 1.667-1.667 1.667z"
																		stroke="#6b7280"
																		strokeWidth="2"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
																<a
																	href={ `${ window.location.origin }/?p=${ article?.id }` }
																>
																	{ sprintf(
																		__(
																			'%s',
																			'wedocs-pro'
																		),
																		extractedTitle(
																			article
																				?.title
																				?.rendered,
																			40
																		)
																	) }
																</a>
																<button
																	onClick={ () =>
																		removeSelectedArticle(
																			article?.id
																		)
																	}
																	className="cursor-pointer hidden group-hover:flex group-hover:items-center ml-auto bg-gray-100 rounded px-2 py-0.5 text-gray-500 text-sm"
																>
																	{ __(
																		'Remove',
																		'wedocs-pro'
																	) }
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		fill="none"
																		viewBox="0 0 24 24"
																		strokeWidth={
																			1.5
																		}
																		stroke="currentColor"
																		className="w-3 h-3 ml-1"
																	>
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			d="M6 18L18 6M6 6l12 12"
																		/>
																	</svg>
																</button>
															</label>
														</li>
													)
												) }
											</ul>
										</div>
									</div>
								) }
						</div>
					</div>
				) }
			{ settingsData?.assistant?.explore?.explore_enable === 'off' && (
				<div
					className="backdrop absolute z-0 top-0 left-0 w-full h-full"
					style={ {
						backgroundColor: 'white',
						opacity: 0.5,
					} }
				/>
			) }
		</div>
	);
};

export default ExploreSettings;
