const WidgetContentPopup = ( { doc, setShowDocContent } ) => {
	return (
		<div className={ `doc-content-container absolute mb-5 inset-0` }>
			<div className={ `doc-overlay w-full h-full absolute bg-[#000] opacity-50 z-0 rounded-[10px]` }></div>
			<div className={ `doc-content h-full flex items-center relative p-4` }>
				<div className={ `single-doc mx-auto w-full h-full bg-white rounded-md border border-[#D1D5DB] h-96 xs:h-64 xs:w-full xs:border-none xs:rounded-0 overflow-y-auto` }>
					<div className={ `heading-container !flex items-center text-gray-900 font-medium text-lg mt-3 mb-1.5 px-6 py-3 pb-2 bg-white top-0 sticky z-10` }>
						<div
							className={ `doc-heading` }
							dangerouslySetInnerHTML={ { __html: doc?.title?.rendered } }
						></div>
						<div className={ `ml-auto` }>
							<svg
								fill='none'
								strokeWidth='1.5'
								viewBox='0 0 24 24'
								stroke='currentColor'
								className='w-6 h-6 cursor-pointer'
								onClick={ () => setShowDocContent( false ) }
							>
								<path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
							</svg>
						</div>
					</div>
					<article
						className={ 'prose break-words px-6 py-3 pt-0' }
						dangerouslySetInnerHTML={ {
							__html: doc?.content?.rendered,
						} }
					></article>
				</div>
			</div>
		</div>
	);
}

export default WidgetContentPopup;
