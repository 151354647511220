import { useSelect } from '@wordpress/data';
import { useEffect, useState } from '@wordpress/element';
import { CheckIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { __, sprintf } from '@wordpress/i18n';
import extractedTitle from '../../utils/extractedTitle';

const ArticleSelectionBox = ( {
	selected,
	anchestors,
	setSelected,
	setAnchestors,
} ) => {
	const [ show, setShow ] = useState( false );
	const [ articles, setArticles ] = useState( [] );

	const handleSelectBox = ( selectedArticleId ) => {
		const selectedIds = selected?.map( ( articleObj ) => articleObj?.id );
		if ( ! selectedIds.includes( selectedArticleId ) ) {
			setSelected( [
				...selected,
				articles?.find(
					( article ) => article?.id === selectedArticleId
				),
			] );
		} else {
			const selectedList = selected?.filter(
				( article ) => article?.id !== selectedArticleId
			);
			setSelected( [ ...selectedList ] );
		}
	};

	const allDocs = useSelect(
		( select ) => select( 'wedocs/docs' ).getDocs(),
		[]
	);

	const parentDocs = allDocs?.filter( ( doc ) => ! doc.parent );

	useEffect( () => {
		const anchestorsArray = [],
			articlesArray = [];

		parentDocs?.forEach( ( doc ) => {
			const sections = allDocs?.filter( ( d ) => d.parent === doc.id );

			sections.forEach( ( section ) => {
				const collection = allDocs?.filter( ( d ) => {
					if ( d.parent === section.id ) {
						anchestorsArray.push( {
							articleId: d?.id,
							documentationTitle: doc?.title?.rendered,
							documentationRef: `${ window.location.origin }/?p=${ doc?.id }`,
							sectionTitle: section?.title?.rendered,
							sectionRef: `${ window.location.origin }/?p=${ section?.id }`,
						} );
					}

					return d.parent === section.id;
				} );

				articlesArray.push( ...collection );
			} );
		} );

		setArticles( [ ...articlesArray ] );
		setAnchestors( [ ...anchestorsArray ] );
	}, [ allDocs ] );

	useEffect( () => {
		const element = document.querySelector( `#select-articles` );

		document.addEventListener( 'click', function ( event ) {
			if ( ! element?.contains( event.target ) ) {
				setShow( false );
			}
		} );
	}, [ show ] );

	return (
		<div id="select-articles" className="multiSelectBox">
			<div className="relative">
				<button
					onClick={ () => setShow( ! show ) }
					className="w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
				>
					<span className="block multiSelectBox truncate">
						{ __( 'Select Articles', 'wedocs-pro' ) }
					</span>
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
							className="h-5 w-5 text-gray-400"
						>
							<path
								d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
								clipRule="evenodd"
							></path>
						</svg>
					</span>
				</button>
			</div>

			{ show && articles && (
				<div className="relative" onClick={ () => setShow( true ) }>
					<ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{ articles &&
							articles?.map( ( article, index ) => (
								<li
									key={ article?.id }
									className="cursor-pointer text-gray-900 hover:bg-[#F9FAFB] select-none !mb-0.5 group"
								>
									<label
										htmlFor="multi-select"
										onClick={ () =>
											handleSelectBox( article?.id )
										}
										className="py-2.5 px-4 font-normal block truncate flex items-center w-full group text-sm leading-5 text-gray-500"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="14"
											height="18"
											fill="none"
											className="mr-2.5"
										>
											<path
												d="M4.5 9h5m-5 3.333h5m1.667 4.167H2.833c-.92 0-1.667-.746-1.667-1.667V3.167c0-.92.746-1.667 1.667-1.667h4.655c.221 0 .433.088.589.244l4.512 4.512c.156.156.244.368.244.589v7.988c0 .921-.746 1.667-1.667 1.667z"
												stroke="#6b7280"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										{ sprintf(
											__( '%s', 'wedocs-pro' ),
											extractedTitle(
												article?.title?.rendered,
												45
											)
										) }
										{ selected
											?.map(
												( articleObj ) => articleObj?.id
											)
											.includes( article?.id ) && (
											<CheckIcon
												className="h-5 w-5 ml-auto text-indigo-700"
												aria-hidden="true"
											/>
										) }
									</label>
									<nav
										className="hidden flex px-4 pb-1.5 group-hover:block"
										aria-label="Breadcrumb"
									>
										<ol
											role="list"
											className="flex items-center"
										>
											<li>
												<div className="flex items-center">
													<a
														href={
															anchestors[ index ]
																?.documentationRef
														}
														className="text-xs leading-5 text-gray-500 hover:text-indigo-700 cursor-pointer"
													>
														{
															anchestors[ index ]
																?.documentationTitle
														}
													</a>
													<ChevronRightIcon
														className="h-3.5 w-3.5 mx-1 flex-shrink-0 text-gray-400"
														aria-hidden="true"
													/>
												</div>
											</li>
											<li>
												<div className="flex items-center">
													<a
														href={
															anchestors[ index ]
																?.sectionRef
														}
														className="text-xs leading-5 text-gray-500 hover:text-indigo-700 cursor-pointer"
													>
														{
															anchestors[ index ]
																?.sectionTitle
														}
													</a>
												</div>
											</li>
										</ol>
									</nav>
								</li>
							) ) }
					</ul>
				</div>
			) }
		</div>
	);
};

export default ArticleSelectionBox;
