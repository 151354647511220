import { __ } from '@wordpress/i18n';
import UploadButton from '../UploadButton';
import AppearanceLabel from '../AppearanceLabel';

const Appearance = ( { settingsData, setSettings, handleExploreContent } ) => {
	return (
		<div className={ `ai-appearance-section relative` }>
			<AppearanceLabel />
			<div className="grid grid-cols-4 gap-5">
				<div className="col-span-4">
					<div className="settings-content flex items-center justify-between">
						<div className="settings-field-heading md:min-w-[300px] mt-1 flex items-center space-x-2 flex-1">
							<label
								className="block text-sm font-medium text-gray-600"
								id="headlessui-listbox-label-15"
								data-headlessui-state="open"
							>
								{ __(
									'Explore tab icon',
									'wedocs-pro'
								) }
							</label>
							<div
								className="tooltip cursor-pointer ml-2"
								data-tip={ __(
									'Upload the new explore tab icon. png/jpg preferred',
									'wedocs-pro'
								) }
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									fill="none"
								>
									<path
										d="M9.833 12.333H9V9h-.833M9 5.667h.008M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 1 1 15 0z"
										stroke="#6b7280"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</div>
						<div className="settings-field w-full max-w-[490px] ml-auto flex flex-2">
							<UploadButton
								setSettings={ setSettings }
								settingsData={ settingsData }
								name={ 'explore_tab_icon' }
								panel={ `explore` }
							/>
						</div>
					</div>
				</div>
				<div className="col-span-4">
					<div className="settings-content flex items-center justify-between">
						<div className="settings-field-heading md:min-w-[300px] flex items-center space-x-2 flex-1">
							<label
								className="block text-sm font-medium text-gray-600"
								id="headlessui-listbox-label-15"
								data-headlessui-state="open"
							>
								{ __( 'Explore tab name', 'wedocs-pro' ) }
							</label>
							<div
								className="tooltip cursor-pointer ml-2"
								data-tip={ __(
									'Give your explore tab a meaningful name',
									'wedocs-pro'
								) }
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									fill="none"
								>
									<path
										d="M9.833 12.333H9V9h-.833M9 5.667h.008M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 1 1 15 0z"
										stroke="#6b7280"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</div>
						<div className="settings-field w-full max-w-[490px] ml-auto flex-2">
							<div className="relative">
								<input
									type="text"
									name="explore_title"
									id="explore-title"
									placeholder={ __(
										'Explore tab title',
										'wedocs-pro'
									) }
									className="w-full !rounded-md !border-gray-300 bg-white !py-1 !pl-3 !pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
									value={
										settingsData?.assistant?.explore?.explore_title || ''
									}
									onChange={ ( e ) =>
										handleExploreContent(
											e,
											'explore_title'
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="col-span-4">
					<div className="settings-content flex items-center justify-between">
						<div className="settings-field-heading md:min-w-[300px] flex items-center space-x-2 flex-1">
							<label
								className="block text-sm font-medium text-gray-600"
								id="headlessui-listbox-label-15"
								data-headlessui-state="open"
							>
								{ __(
									'Explore Tab Subtitle 1',
									'wedocs-pro'
								) }
							</label>
							<div
								className="tooltip cursor-pointer ml-2"
								data-tip={ __(
									'Edit the first explore tab subtitle',
									'wedocs-pro'
								) }
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									fill="none"
								>
									<path
										d="M9.833 12.333H9V9h-.833M9 5.667h.008M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 1 1 15 0z"
										stroke="#6b7280"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</div>
						<div className="settings-field w-full max-w-[490px] ml-auto flex-2">
							<div className="relative">
								<input
									type="text"
									name="explore_subtitle_one"
									id="explore-subtitle-one"
									placeholder={ __(
										'Explore tab subtitle 1',
										'wedocs-pro'
									) }
									className="w-full !rounded-md !border-gray-300 bg-white !py-1 !pl-3 !pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
									value={
										settingsData?.assistant?.explore?.explore_subtitle_one || ''
									}
									onChange={ ( e ) =>
										handleExploreContent(
											e,
											'explore_subtitle_one'
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="col-span-4">
					<div className="settings-content flex items-center justify-between">
						<div className="settings-field-heading md:min-w-[300px] flex items-center space-x-2 flex-1">
							<label
								className="block text-sm font-medium text-gray-600"
								id="headlessui-listbox-label-15"
								data-headlessui-state="open"
							>
								{ __(
									'Explore Tab Subtitle 2',
									'wedocs-pro'
								) }
							</label>
							<div
								className="tooltip cursor-pointer ml-2"
								data-tip={ __(
									'Edit the second explore tab subtitle',
									'wedocs-pro'
								) }
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									fill="none"
								>
									<path
										d="M9.833 12.333H9V9h-.833M9 5.667h.008M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 1 1 15 0z"
										stroke="#6b7280"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</div>
						<div className="settings-field w-full max-w-[490px] ml-auto flex-2">
							<div className="relative">
								<input
									type="text"
									name="explore_subtitle_two"
									id="explore-subtitle-two"
									placeholder={ __(
										'Explore tab subtitle 2',
										'wedocs-pro'
									) }
									className="w-full !rounded-md !border-gray-300 bg-white !py-1 !pl-3 !pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
									value={
										settingsData?.assistant?.explore?.explore_subtitle_two || ''
									}
									onChange={ ( e ) =>
										handleExploreContent(
											e,
											'explore_subtitle_two'
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{ settingsData?.assistant?.explore?.explore_enable === 'off' && (
				<div
					className="backdrop absolute z-0 top-0 left-0 w-full h-full"
					style={ { backgroundColor: 'white', opacity: 0.5 } }
				/>
			) }
		</div>
	);
}

export default Appearance
