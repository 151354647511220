import { SketchPicker } from 'react-color';
import { useEffect, useState } from '@wordpress/element';

const LayoutColorSettings = ( {
    option,
    setSettings,
    activeColors,
    settingsData,
} ) => {
	const [ color, setColor ] = useState( { ...option?.default } );
	const [ displayColorPicker, setDisplayColorPicker ] = useState( false );
	const [ defaultColorSelect, setDefaultColorSelect ] = useState( false );

	const presetColors = [
		'#EF4444',
		'#F97316',
		'#FACC15',
		'#4ADE80',
		'#2DD4BF',
		'#3B82F6',
		'#6467F2',
		'#EC4899',
		'#F43F5E',
		'#D946EF',
		'#8B5CF6',
		'#0EA5E9',
		'#10B981',
		'#84CC16',
	];

	const handleClick = () => {
		setDisplayColorPicker( ! displayColorPicker );
	};

	const handleClose = () => {
		setDisplayColorPicker( false );
	};

	const defaultColorSet = [
		{ r: '0', g: '0', b: '0', a: '1' },
		{ r: '36', g: '192', b: '48', a: '1' },
		{ r: '75', g: '189', b: '255', a: '1' },
		{ r: '254', g: '74', b: '90', a: '1' },
		{ r: '252', g: '185', b: '2', a: '1' },
		{ r: '189', g: '16', b: '224', a: '1' },
		{ r: '28', g: '135', b: '234', a: '1' },
	];

	useEffect( () => {
		const defaultSelect = defaultColorSet?.find(
			( colorObj ) =>
				JSON.stringify( colorObj ) === JSON.stringify( color )
		);

		setDefaultColorSelect( Boolean( defaultSelect ) );
		setSettings( {
			...settingsData,
			layout: {
				...settingsData?.layout,
				[ option?.key ]: color,
			},
		} );
	}, [ color ] );

	useEffect( () => {
		setColor( activeColors?.[ option?.key ] );
		setSettings( {
			...settingsData,
			layout: {
				...settingsData?.layout,
				active_text: { ...activeColors?.active_text },
				active_nav_bg: { ...activeColors?.active_nav_bg },
				active_nav_text: { ...activeColors?.active_nav_text },
			},
		} );
	}, [ activeColors ] );

	return (
		<div className="color-container flex gap-2.5">
			<div
				className="flex justify-center items-center space-x-1 px-2 py-1.5 rounded-md bg-white border border-[#E2E2E2] cursor-pointer relative"
				onClick={ handleClick }
			>
				{ ! defaultColorSelect && (
					<div
						style={ {
							background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
						} }
						className={ `${
							color.r === 255 &&
							color.g === 255 &&
							color.b === 255
								? 'border border-[#DBDBDB]'
								: ''
						} w-6 h-6 rounded-full flex justify-center items-center` }
					/>
				) }
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 20 20"
					fill="currentColor"
					aria-hidden="true"
					className="h-4 w-4 stroke-gray-500"
					strokeWidth={ 1 }
				>
					<path
						d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
						clipRule="evenodd"
					></path>
				</svg>
			</div>
			{ displayColorPicker ? (
				<div className="absolute z-[2]">
					<div onClick={ handleClose } className="fixed inset-0" />
					<SketchPicker
						color={ color }
						className="!w-60 !p-4 !pb-1.5 !rounded-lg border border-gray-300 !shadow"
						presetColors={ presetColors }
						onChangeComplete={ ( c ) => {
							setColor( c.rgb );
						} }
					/>
				</div>
			) : null }
		</div>
	);
};

export default LayoutColorSettings;
