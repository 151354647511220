import {
	DndContext,
	closestCenter,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';

import { arrayMove } from '@dnd-kit/sortable';

const DraggableTabs = ( { setItems, settingsData, setSettings, children } ) => {
	const sensors = useSensors(
		useSensor( PointerSensor, {
			activationConstraint: {
        distance: 10,
			},
		} )
	);

	const handleDragEnd = ( event ) => {
		const { active, over } = event;

		if ( active?.id !== over?.id ) {
			let placementOrder = [];
			setItems( ( elements ) => {
				const oldItem = elements.find(
					( element ) => element?.id === active?.id
				);

				const newItem = elements.find(
					( element ) => element?.id === over?.id
				);
				const oldIndex = elements.indexOf( oldItem );
				const newIndex = elements.indexOf( newItem );
				const updatedOrder = arrayMove( elements, oldIndex, newIndex );

				placementOrder = updatedOrder.map( tab => tab.key );
				return updatedOrder;
			} );

			setSettings( {
				...settingsData,
				assistant: {
					...settingsData?.assistant,
					placement: {
						...settingsData?.assistant?.placement,
						order: [ ...placementOrder ],
					}
				},
			} );
		}
	};

	return (
		<DndContext
			class="z-10"
			sensors={ sensors }
			onDragEnd={ handleDragEnd }
			collisionDetection={ closestCenter }
		>
			{ children }
		</DndContext>
	);
};

export default DraggableTabs;
