import { __ } from '@wordpress/i18n';
import Appearance from './Appearance';
import AssistantPanel from '../AssistantPanel';
import PanelBody from '../PanelBody';
import ExploreEnable from './ExploreEnable';
import ExploreSettings from './ExploreSettings';

const ExplorePanel = ( { settingsData, setSettings } ) => {
	const handleExploreContent = ( e, field ) => {
		setSettings( {
			...settingsData,
			assistant: {
				...settingsData?.assistant,
				explore: {
					...settingsData?.assistant?.explore,
					[ field ]: e.target.value,
				},
			},
		} );
	};

	const tabs = [
		__( 'Explore', 'wedocs-pro' ),
		__( 'Appearance', 'wedocs-pro' ),
	];

	const panels = [
		<ExploreSettings
			settingsData={ settingsData }
			setSettings={ setSettings }
		/>,
		<Appearance
			settingsData={ settingsData }
			setSettings={ setSettings }
			handleExploreContent={
				handleExploreContent
			}
		/>
	];

	return (
		<AssistantPanel
			settingsData={ settingsData }
			setSettings={ setSettings }
		>
			<PanelBody
				tabs={ tabs }
				panels={ panels }
			>
				<ExploreEnable
					settingsData={ settingsData }
					setSettings={ setSettings }
				/>
			</PanelBody>
		</AssistantPanel>
	);
}

export default ExplorePanel;
