import { __ } from '@wordpress/i18n';
import ColorPicker from '../../ColorPicker';
import { useEffect } from '@wordpress/element';

const CustomColorSettings = ( {
	setSettings,
	settingsData,
	previewColors,
	setPreviewColors,
} ) => {
	const customOptions = [
		{
			key: 'widgetBg',
			title: __( 'Widget Background Color', 'wedocs-pro' ),
			tooltip: __( 'Choose a color that ensures good visibility for the widget background', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.widgetBg,
			},
		},
		{
			key: 'activeTabBg',
			title: __( 'Active Tab Background Color', 'wedocs-pro' ),
			tooltip: __( 'Choose a vibrant color to indicate the active tab and make it visually prominent', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.activeTabBg,
			},
		},
		{
			key: 'activeTabFont',
			title: __( 'Active Tab Font and Icon Color', 'wedocs-pro' ),
			tooltip: __( 'Choose a font and icon color that provides a clear contrast with the active tab background color', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.activeTabFont,
			},
		},
		{
			key: 'inactiveTabBg',
			title: __( 'Inactive Tab Background Color', 'wedocs-pro' ),
			tooltip: __( 'Choose a lighter color for inactive tabs to differentiate from the active tabs', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.inactiveTabBg,
			},
		},
		{
			key: 'inactiveTabFont',
			title: __( 'Inactive Tab Font and Icon Color', 'wedocs-pro' ),
			tooltip: __( 'Choose a font and icon color that contrasts with the inactive tab background color', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.inactiveTabFont,
			},
		},
		{
			key: 'tabTitleFont',
			title: __( 'Tab First Sub-Title Font Color', 'wedocs-pro' ),
			tooltip: __( 'Choose a font color that ensures good visibility for the first sub-title in the tabs', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.tabTitleFont,
			},
		},
		{
			key: 'tabDescriptionFont',
			title: __( 'Tab Second Sub-Title Font Color', 'wedocs-pro' ),
			tooltip: __( 'Choose a font color that ensures good visibility for the second sub-title in the tabs', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.tabDescriptionFont,
			},
		},
		{
			key: 'breadcrumbColor',
			title: __( 'Breadcrumb Color', 'wedocs-pro' ),
			tooltip: __( 'Choose a color that stands out against the background to track the navigation', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.breadcrumbColor,
			},
		},
		{
			key: 'sendBtnBg',
			title: __( 'Send Button Background Color', 'wedocs-pro' ),
			tooltip: __( 'Choose a contrasting color for the send button background to encourage user interaction', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.sendBtnBg,
			},
		},
		{
			key: 'sendBtnFont',
			title: __( 'Send Button Text Color', 'wedocs-pro' ),
			tooltip: __( 'Choose a text color for the send button that provides good contrast', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.sendBtnFont,
			},
		},
		{
			key: 'bubbleBg',
			title: __( 'Bubble Background Color', 'wedocs-pro' ),
			tooltip: __( 'Choose a visually appealing color for the bubble background', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.bubbleBg,
			},
		},
		{
			key: 'bubbleIcon',
			title: __( 'Bubble Icon Color', 'wedocs-pro' ),
			tooltip: __( 'Choose an icon color that provides good contrast against the bubble background color', 'wedocs-pro' ),
			default: {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors
					?.bubbleIcon,
			},
		},
	];

	useEffect( () => {
		if (
			'custom' ===
			settingsData?.assistant?.preference?.color_settings?.palette_settings
		) {
			setPreviewColors( {
				...settingsData?.assistant?.preference?.color_settings?.preview_colors,
			} );
		}
	}, [ settingsData?.assistant?.preference?.color_settings?.palette_settings ] );

	return (
		<div id="custom-settings" className="w-80">
			<div className="custom-options">
				{ customOptions?.map( ( option ) => (
					<div
						key={ option?.key }
						className="custom-option flex items-center justify-between mb-6 last:mb-0 rounded-lg hover:cursor-pointer"
					>
						<h4 className="flex items-center font-medium text-gray-600 text-sm">
							{ option?.title }
							<div
								className="tooltip cursor-pointer ml-2"
								data-tip={ option?.tooltip }
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									fill="none"
								>
									<path
										d="M9.833 12.333H9V9h-.833M9 5.667h.008M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 1 1 15 0z"
										stroke="#6b7280"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</h4>
						<div className="color flex items-center gap-2">
							<ColorPicker
								option={ option }
								setSettings={ setSettings }
								settingsData={ settingsData }
								previewColor={ previewColors }
								setPreviewColor={ setPreviewColors }
							/>
						</div>
					</div>
				) ) }
			</div>
		</div>
	);
};

export default CustomColorSettings;
