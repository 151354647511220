import { __, sprintf } from '@wordpress/i18n';
import MultiSelectBox from '../MultiSelectBox';
import { useSelect } from '@wordpress/data';
import { useState } from '@wordpress/element';

const PermissionSettings = ( { settingsData, setSettings } ) => {
	const roles = useSelect(
		( select ) => select( 'wedocs/settings' ).getRoles(),
		[]
	);

	const { permission } = settingsData;

	const {
		global_permission: globalPermission,
		role_wise_permission: roleWisePermission,
	} = permission;

	const permissionFields = [
		{
			boxId: 'global_permission',
			permissionObj: globalPermission,
			title: __( 'Global doc edit permission settings', 'wedocs-pro' ),
			tooltip: __(
				'Customize roles to grant both new document creation & editing privileges to all docs',
				'wedocs-pro'
			),
		},
		{
			boxId: 'role_wise_permission',
			permissionObj: roleWisePermission,
			title: __( 'Who can manage weDocs Settings?', 'wedocs-pro' ),
			tooltip: __(
				'This settings allows other user roles to interact with the Settings panel of weDocs itself. Only select user roles that you want to give permission to alter weDocs plugin\'s internal settings',
				'wedocs-pro'
			),
		},
	];

	const [ excludedRole, setExcludedRole ] = useState( {} );

	const handleUserPermission = ( boxId, role ) => {
		setExcludedRole( { boxId, role } );
	};

	return (
		<section>
			<div className="shadow sm:rounded-md bg-white min-h-[500px]">
				<div className="py-4 px-8 sm:px-8 sm:py-4">
					<h2 className="text-gray-900 font-medium text-lg">
						{ __( 'Permission Management', 'wedocs-pro' ) }
					</h2>
				</div>
				<hr className="h-px !bg-gray-200 border-0 dark:!bg-gray-200" />
				<div className="pt-6 pb-20 px-8 grid grid-cols-4 gap-5">
					{ permissionFields &&
						permissionFields?.map( ( field, fieldIndex ) => (
							<div className="col-span-4" key={ fieldIndex }>
								<div className="settings-content flex items-center justify-between">
									<div className="settings-heading md:min-w-[300px] flex items-center space-x-2 flex-1">
										<label
											className="block text-sm font-medium text-gray-700"
											id="headlessui-listbox-label-15"
											data-headlessui-state="open"
										>
											{ field?.title }
										</label>
										<div
											className="tooltip cursor-pointer ml-2"
											data-tip={ field?.tooltip }
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="18"
												height="18"
												fill="none"
											>
												<path
													d="M9.833 12.333H9V9h-.833M9 5.667h.008M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 1 1 15 0z"
													stroke="#6b7280"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</div>
									</div>
									<div className="settings-field w-full max-w-[490px] mt-1 ml-auto flex-2">
										<MultiSelectBox
											boxId={ field?.boxId }
											options={ roles }
											settingsData={ settingsData }
											setSettings={ setSettings }
											excludedOption={ excludedRole }
										/>
									</div>
								</div>
								{ field?.permissionObj &&
									field?.permissionObj?.length > 0 && (
										<div className="settings-description max-w-[490px] ml-auto">
											<div className="active-roles inline-flex flex-wrap items-center gap-2.5">
												{ field?.permissionObj?.map(
													( role, index ) => (
														<span
															key={ index }
															className="inline-flex items-center gap-1 rounded-md bg-gray-100 px-2.5 py-0.5 text-sm text-gray-800"
														>
															{ sprintf(
																__(
																	'%s',
																	'wedocs-pro'
																),
																role
																	.charAt( 0 )
																	.toUpperCase() +
																	role.slice(
																		1
																	)
															) }
															<svg
																xmlns="http://www.w3.org/2000/svg"
																fill="none"
																viewBox="0 0 24 24"
																strokeWidth={
																	1.5
																}
																onClick={ () => role !== 'administrator' ?
																	handleUserPermission(
																		field?.boxId,
																		role
																	) : ''
																}
																stroke="currentColor"
																className="w-4 h-4 cursor-pointer"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	d="M6 18L18 6M6 6l12 12"
																/>
															</svg>
														</span>
													)
												) }
											</div>
										</div>
									) }
							</div>
						) ) }
				</div>
			</div>
		</section>
	);
};

export default PermissionSettings;
