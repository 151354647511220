import React, { useEffect, useState } from '@wordpress/element';

const TypewriterEffect = ({ text, typingSpeed = 25, children }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [finishWriting, setFinishWriting] = useState(false);

  useEffect(() => {
    if (text && currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText((prevDisplayedText) => prevDisplayedText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, typingSpeed);

      return () => clearTimeout(timer);
    }else{
      setFinishWriting(true);
    }
  }, [currentIndex, text, typingSpeed]);

  return <span>
    {displayedText}
    {finishWriting && children}
  </span>;
};

export default TypewriterEffect;
