import { __ } from '@wordpress/i18n';
import AssistantSwitcher from '../AssistantSwitcher';
import apiFetch from '@wordpress/api-fetch';
import Swal from 'sweetalert2';
import { Fragment } from '@wordpress/element';

const AiEnable = ( { resetAI, connectedWithAi, settingsData, setSettings } ) => {
  const buySubscription = () => {
    apiFetch({
      path: '/wp/v2/docs/settings/ai-integration',
      method: 'POST',
    }).then((response) => {
      if (response.message.status !== 200 && response.message.payment_link !== null && response.message.payment_link !== undefined) {
        location.href = response.message.payment_link + '&redirect_to=' +
          encodeURIComponent(location.href) + '&password=' + response.aiPassword
      }else {
        location.reload()
      }
    })
    .catch((error) => {
      Swal.fire( {
        title: __( 'Oops! It looks like you haven\'t subscribed to our amazing A.I. Chat feature yet', 'wedocs-pro' ),
        text: __( 'We\'re redirecting you to the purchase page. You\'re one click away from having a smart, helpful assistant by your side 24/7', 'wedocs-pro' ),
        icon: 'error',
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
      } );

      if (error.message.status !== 200 && error.message.payment_link !== null && error.message.payment_link !== undefined) {
        setTimeout(() => {
          location.href = error.message.payment_link + '&redirect_to=' + encodeURIComponent(location.href) + '&password=' + error.aiPassword
        }, 2000)
      }

      return false;
    })
  };

  const handleAIEnableToggle = (value) => {
    if ( connectedWithAi === undefined) {
      Swal.fire( {
        title: __( 'Error', 'wedocs-pro' ),
        text: __(
          'Please connect to A.I. first',
          'wedocs-pro'
        ),
        icon: 'error',
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
      } );
      return false
    }
    if (value) {
      apiFetch({
        path: '/wp/v2/docs/settings/ai-integration',
        method: 'POST',
      }).then((response) => {
        return response
      }).then((response) => {
        if (response.message.status !== 200) {
          Swal.fire( {
            title: __( 'Error', 'wedocs-pro' ),
            text: response.message.message,
            icon: 'error',
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 2000,
          } );
          location.href = response.message.payment_link + '&redirect_to=' +
            encodeURIComponent(location.href) + '&password=' + settingsData?.integrate_ai?.ai_password
        }
      }).catch((error) => {
        Swal.fire( {
          title: __( 'Error', 'wedocs-pro' ),
          text: error.message.message,
          icon: 'error',
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 2000,
        } );
        // if (error.message.status === 403) {
        // 	resetAI()
        // }
        if (error?.message?.payment_link !== null && error?.message?.payment_link !== undefined) {
          location.href = error.message.payment_link + '&redirect_to=' +
            encodeURIComponent(location.href) + '&password=' + settingsData?.integrate_ai?.ai_password
        }
      })
    }
  };
  const WP_AI_RESET = weDocsPro_Vars?.WP_AI_RESET

  return (
    <Fragment>
			{ ( connectedWithAi === undefined || connectedWithAi === '' || connectedWithAi !== true ) && (
        <div className={ `w-11/12 w-[612px] border border-[#DBDBDB] rounded-md p-5 mx-auto text-center` }>
					<p className={ `text-sm text-gray-500 leading-5 mb-2` }>
						{ __( 'Add-on:', 'wedocs-pro' ) }
					</p>
					<h3 className={ `text-xl text-gray-900 font-medium leading-6 mb-4` }>
						{ __( 'AI Chatbot', 'wedocs-pro' ) }
					</h3>
					<p className={ `leading-5 text-sm text-gray-500 mb-6` }>
						{ __( 'Elevate your weDocs experience with weDocs AI Chat, powered by ChatGPT! Seamlessly integrate our AI Chat for effortless user queries and precise responses.', 'wedocs-pro' ) }
					</p>
					<h2 className={ `text-[32px] text-gray-900 font-medium leading-6 flex items-center justify-center mb-9` }>
						$7.99
						<span className={ `text-gray-500 text-sm leading-5 ml-1` }>
						{ __( '/mo', 'wedocs-pro' ) }
					</span>
					</h2>
					<div className={ `flex items-center justify-center space-x-4` }>
						<button
              type='button'
              onClick={ buySubscription }
              className='opacity-100 min-w-[136px] inline-flex shadow shadow-lg shadow-gray-800/30 items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
            >
							{ __( 'Buy Subscription', 'wedocs-pro' ) }
						</button>
            { WP_AI_RESET && (
              <button
                type='button'
                onClick={ resetAI }
                className='opacity-100 min-w-[136px] shadow shadow-lg shadow-gray-800/30 items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-center'
              >
								{ __( 'Reset A.I.', 'wedocs-pro' ) }
							</button>
            ) }
					</div>
				</div>
      ) }
      { ( connectedWithAi !== undefined &&
        connectedWithAi !== '' &&
        connectedWithAi === true ) && (
        <div className="grid grid-cols-4 gap-5">
					<div className="col-span-4" key="enable_ai">
						<div className="settings-content flex items-center justify-between">
							<div className="settings-field-heading md:min-w-[300px] flex items-center space-x-2 flex-1">
								<label
                  className="block text-sm font-medium text-gray-600"
                  id="headlessui-listbox-label-15"
                  data-headlessui-state="open"
                >
									{ ( settingsData?.integrate_ai?.ai_password === undefined ||
                    settingsData?.integrate_ai?.ai_password === '') ?
                    __('Connect to A.I.', 'wedocs-pro') : __('Enable A.I.', 'wedocs-pro')
                  }
								</label>
								<div
                  className="tooltip cursor-pointer ml-2"
                  data-tip={ __(
                    'Subscribe to enable a powerful A.l. Chatbot for\n' +
                    'your site that learns from your docs and provides instant answers. Powered by ChatGPT',
                    'wedocs-pro'
                  ) }
                >
									<svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="none"
                  >
										<path
                      d="M9.833 12.333H9V9h-.833M9 5.667h.008M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 1 1 15 0z"
                      stroke="#6b7280"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
									</svg>
								</div>
							</div>
							<div className="settings-field w-full max-w-[490px] ml-auto flex-2">
								<div className="relative flex items-center">
									<AssistantSwitcher
                    name="ai_enabled"
                    isEnabled={ settingsData?.assistant?.integrate_ai?.ai_enabled === 'on' }
                    settingsPanel={ settingsData?.assistant?.integrate_ai }
                    settingsData={ settingsData }
                    setSettings={ setSettings }
                    onChange={ e => handleAIEnableToggle( e ) }
                    panelName={ `integrate_ai` }
                  />
								</div>
							</div>
						</div>
					</div>
				</div>
      ) }
      <hr className={ `h-px !bg-gray-200 border-0 dark:!bg-gray-200 ${ connectedWithAi ? 'my-5' : 'my-7' }` } />
		</Fragment>
  );
}

export default AiEnable;
