import { __ } from '@wordpress/i18n';

function extractedTitle( title, length = 60 ) {
	const extractedString = title?.substr( 0, length );
	return extractedString?.length >= length
		? __( `${ extractedString }...`, 'wedocs-pro' )
		: __( extractedString, 'wedocs-pro' );
}

export default extractedTitle;
