import ManagerHeader from './ManagerHeader';
import SettingsSection from './SettingsSection';

const ManagerSettings = () => {
	return (
		<>
			<ManagerHeader />
			<SettingsSection />
		</>
	);
};

export default ManagerSettings;
