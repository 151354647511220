import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from '@wordpress/element'
import apiFetch from '@wordpress/api-fetch'
import TypewriterEffect from '../TypewriterEffect'
import { __ } from '@wordpress/i18n'
import GuestAvatar from '../../../assets/img/guest-avatar.jpg'
import { getCurrentUserId } from '../../../utils/helper';

export const AiChat = ({ frontendPreview }) => {
  const chatContainerRef = useRef(null)
  const [chat, setChat] = useState([])
  const [text, setText] = useState('')
  const [userAvatar, setUserAvatar] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const textRef = useRef(null)

  	let currentUserId = getCurrentUserId()

  const addChat = async (event) => {
    if (event.key === 'Enter') {
      const userInput = event.currentTarget.value
      if (userInput === '') {
        return
      }

      setIsLoading(true)
      setText('')

      // Add user input to chat state
      const userChatMessage = {
        text: userInput,
        image: userAvatar,
        isMe: true,
      }
      setChat(prevChat => [...prevChat, userChatMessage])

      // Call askGpt and update the last item in chat state
      const response = await askGpt(userInput)
      let readMore = ''
      if (response.post_links && response.post_links.length > 0) {
	    readMore += `<br/><ul style="margin: 0; list-style: none;" >`;
        response.post_links.forEach((info, index) => {
		  if ( info?.title ) {
		    readMore += '<li style="margin-bottom: 6px;">' +
			  '<sup style="margin-right: 8px;">[' + ( index + 1 ) + ']</sup>' +
			  '<a href="' + info?.permalink + '" target="_blank" rel="noopener noreferrer">' + info?.title + '</a>' +
		    '</li>';
		  }
        })
	    readMore += '</ul>';
      }
      const botChatMessage = {
        text: response.chat,
        readMore: readMore,
        image: 'https://cdn-icons-png.flaticon.com/512/3398/3398648.png',
        isMe: false,
      }
      setIsLoading(false)
      setChat(prevChat => [...prevChat, botChatMessage])

    }
  }

  const askGpt = async ( prompt ) => {
    if ( prompt === '' ) {
      return ''
    }

    return await apiFetch({
      path	 : '/wp/v2/docs/settings/ai-integration/chat',
	  data	 : { prompt },
	  method : 'POST',
    })
	.then( response => response )
    .catch( error => {
      console.log( error, 'error' )
      return {
        chat: __( 'Sorry, I am not able to understand you. Please try again.', 'wedocs-pro' ),
        post_links: [],
      }
    })
  }

  // Scroll to the bottom of the chat container after each message is added
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [chat])

  useEffect(() => {
	  if ( currentUserId ) {
		  apiFetch({
			  path: '/wp/v2/users/' + currentUserId,
		  }).then((result) => {
			  setUserAvatar(result?.avatar_urls?.['24'])
		  }).catch((err) => {})
	  }
  }, [currentUserId])

  return (
    <div className="container mx-auto rounded-lg">
      <div className="flex flex-row justify-between bg-white">
        <div className="w-full px-7 xxs:px-4 flex flex-col justify-between">
          <div
            className="flex flex-col mt-5 h-[266px] xs:h-[170px] overflow-auto"
            ref={chatContainerRef}
          >
            {chat.map((item, index) => {
              let dynamicId = 'dynamicId' + index
              return (
                <>
                  <div
                    key={index}
                    className={`flex justify-${item.isMe
                      ? 'end'
                      : 'start'} mb-4`}
                  >
                    {!item.isMe && (
                      <div className="ai-icon min-w-[32px] h-8 rounded-full bg-[#FDA92A] flex items-center justify-center">
                        <svg
                          width="27"
                          height="26"
                          fill="none"
                        >
                          <path
                            fill="#fff"
                            fillRule="evenodd"
                            d="M3.295 3.38a1.33 1.33 0 0 1 .541 2.544v3.941l.215-.022c.974-4.101 4.804-6.463 9.381-6.463s8.406 2.361 9.381 6.461a4.78 4.78 0 0 1 .215.019V5.923a1.33 1.33 0 0 1 .542-2.543 1.33 1.33 0 0 1 .542 2.543v4.218c.707.324 1.184.913 1.233 1.827a67.2 67.2 0 0 1 .004.739l-.001.999c-.074 1.382-1.12 2.025-2.466 2.129-.845 4.297-4.755 6.784-9.45 6.784s-8.605-2.489-9.45-6.788c-1.345-.126-2.389-.879-2.461-2.258-.003-.052-.004-.814-.004-.866l.004-.606c.05-.914.526-1.552 1.234-1.914V5.923a1.33 1.33 0 0 1 .542-2.543zm17.635 8.384c.007.138.011 1.096.011 1.236l-.012 1.221c-.208 3.96-3.486 6.288-7.498 6.288s-7.289-2.328-7.498-6.288c-.007-.133-.01-1.086-.01-1.221l.011-1.236c.216-3.954 3.49-6.273 7.498-6.273s7.282 2.319 7.498 6.273zm-2.729-.127c0-.782-.632-1.415-1.415-1.415s-1.415.633-1.415 1.415.632 1.415 1.415 1.415 1.415-.632 1.415-1.415zm-6.716 0c0 .781-.633 1.415-1.415 1.415s-1.415-.633-1.415-1.415.633-1.415 1.415-1.415 1.415.633 1.415 1.415z"
                          />
                        </svg>
					            </div>
                    )}
                    <div
                      id={dynamicId}
                      ref={textRef}
                      className={`${item.isMe
                        ? 'mr-2 py-3 px-4 xs:py-2 xs:px-3 bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl xs:leading-6 text-white'
                        : 'ml-2 py-3 px-4 xs:py-2 xs:px-3 rounded-br-3xl rounded-tr-3xl rounded-tl-xl xs:leading-6 text-gray-600'}`}
                    >
                      {item.isMe && (
                        <span dangerouslySetInnerHTML={{ __html: item.text }} />)}
                      {!item.isMe && (
                        <>
                          <TypewriterEffect text={item.text}>
                            <div dangerouslySetInnerHTML={{ __html: item.readMore }} />
                          </TypewriterEffect>
                        </>
                      )}
                    </div>
                    {item.isMe && (
                      <Fragment>
                        {item.image ? (
                          <img
                            src={item.image}
                            className="object-cover h-8 w-8 rounded-full"
                            alt=""
                          />
                        ) : (
                          <img
                            src={GuestAvatar}
                            className="object-cover h-8 w-8 rounded-full"
                            alt={__('Guest Avatar Icon', 'wedocs-pro')}
                          />
                        )}
                      </Fragment>
                    )}
                  </div>
                </>
              )
            })}
            {isLoading && <div>{__('Loading...', 'wedocs-pro')}</div>}
          </div>
          <div className="py-4 xxs:pb-0">
            <input
              className={`${frontendPreview
                ? 'text-black !py-3.5 !px-6'
                : '!text-[#6B7280] shadow-transparent !py-2 !px-5'} xs:!px-3 xs:!py-2 w-full !border !border-gray-300 !rounded-md !bg-white`}
              value={text}
              autoFocus={frontendPreview}
              onChange={(e) => {
                setText(e.currentTarget.value)
              }}
              onKeyUp={(e) => {
                addChat(e)
              }}
              type="text"
              readOnly={!frontendPreview}
              placeholder={__('Ask me anything about this website...',
                'wedocs-pro')}
            />
          </div>
        </div>
      </div>
  </div>
  )

}
