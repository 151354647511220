import MessagePreview from '../Settings/AssistantWidgetPanels/MessagePreview';
import { useSelect } from '@wordpress/data';
import { getCurrentPageId } from '../../utils/helper';
import { useEffect, useState } from '@wordpress/element'

const Bubble = () => {

	// Get assistant settings from localized variable.
	const isAssistEnable = weDocsPro_Front_Vars?.assistant_settings?.assist_enable === 'off';
	const isAiEnable = weDocsPro_Front_Vars?.assistant_settings?.integrate_ai?.ai_enabled === 'off';
	const isExploreEnable = weDocsPro_Front_Vars?.assistant_settings?.explore?.explore_enable === 'off';
	const isMessageEnable = weDocsPro_Front_Vars?.assistant_settings?.message?.messaging_enable === 'off';

  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    // Function to check if DOM is fully loaded
    const checkDomReady = () => {
      if (document.readyState === 'complete') {
        setShowContent(true);

        // Small delay to ensure all calculations are done
        setTimeout(() => {
          setShowContent(true);
        }, 100);
      }
    };

    // Check immediately
    checkDomReady();

    // Also listen for the load event
    window.addEventListener('load', checkDomReady);

    return () => {
      window.removeEventListener('load', checkDomReady);
    };
  }, []);

	useEffect( () => {
		const widgetLinks = document.querySelectorAll( 'a[rel~="widget-link"]' );

		const handleClick = event => {
			event.preventDefault();
		};

		// Handle link enhancer link control.
		if ( isAssistEnable ) {
			widgetLinks.forEach( linkDom => {
				const newButton = document.createElement( 'a' );
				newButton.innerHTML = linkDom.innerHTML;

				for ( let i = 0; i < linkDom.attributes.length; i++ ) {
					const attr = linkDom.attributes[i];
					newButton.setAttribute( attr.name, attr.value );
				}

				linkDom.replaceWith( newButton );
			} );
		} else {
			widgetLinks.forEach( linkDom => linkDom.addEventListener( 'click', handleClick ) );
		}
	}, [ isAssistEnable ] );

	return (
		<div
			style={ { position: 'fixed' } }
			className='xs:bottom-4 xs:right-6 xxs:right-3 md:bottom-4 md:right-6 bottom-8 right-14 z-[999]'
		>
			<span className={ `hidden font-extrabold` }></span>
			{ showContent && weDocsPro_Front_Vars.assistant_settings &&
				! isAssistEnable &&
				! ( isAiEnable && isExploreEnable && isMessageEnable ) && (
					<MessagePreview
						previewColors={
							weDocsPro_Front_Vars?.assistant_settings?.preference?.color_settings?.preview_colors
						}
						settings={ weDocsPro_Front_Vars?.assistant_settings }
						bodyClasses={
							'-translate-y-full absolute right-0 ml-auto'
						}
						iconClasses={
							'mt-24 cursor-pointer hover:bg-indigo-600'
						}
						frontendPreview={ true }
					/>
				) }
		</div>
	);
};

export default Bubble;
