import { __ } from '@wordpress/i18n';

const AssistantPanel = ( { children, settingsData, setSettings } ) => {
	const { switchComponent: Switcher } = window;

	return (
		<section>
			<div className="shadow sm:rounded-md bg-white min-h-[500px]">
				<div className="flex justify-between items-center py-4 px-8 sm:px-8 sm:py-4">
					<div className="flex items-center space-x-3">
						<h2 className="text-gray-900 font-medium text-lg">
							{ __( 'Assistant Widget', 'wedocs-pro' ) }
						</h2>
						<div
							className="tooltip cursor-pointer ml-2 z-[99999]"
							data-tip={ __(
								'Enable a dynamic assistant widget with Explore for finding relevant documentation at your fingertips, A.I. Chat for instant smart answers, and Messaging features for seamless communication between your audience and yourself',
								'wedocs-pro'
							) }
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="18"
								fill="none"
							>
								<path
									d="M9.833 12.333H9V9h-.833M9 5.667h.008M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 1 1 15 0z"
									stroke="#6b7280"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
					</div>
					<div className="flex items-center">
						{ Switcher && (
							<Switcher
								name="assist_enable"
								settingsPanel={ settingsData?.assistant }
								settingsData={ settingsData }
								setSettings={ setSettings }
								panelName={ `assistant` }
								isEnabled={ settingsData?.assistant?.assist_enable !== 'off' }
							/>
						) }
					</div>
				</div>
				<hr className="h-px !bg-gray-200 border-0 dark:!bg-gray-200" />

				{/* Render panels body. */}
				<div className={ `pt-6 pb-20 px-8 relative` }>
					{ children }
					{ settingsData?.assistant?.assist_enable === 'off' && (
						<div
							className="backdrop absolute z-0 top-0 left-0 w-full h-full"
							style={ { backgroundColor: 'white', opacity: 0.5 } }
						/>
					) }
				</div>
			</div>
		</section>
	);
}

export default AssistantPanel;
