import { __ } from '@wordpress/i18n'
import { Fragment, useEffect, useState } from '@wordpress/element'
import apiFetch from '@wordpress/api-fetch'
import Swal from 'sweetalert2'

const IntegrateAiSettings = ({ settingsData, connectedWithAi }) => {
  const [billingInfo, setBillingInfo] = useState({
    status: false,
    paid_amount: 0,
    interval: '',
    next_payment_date: 0,
  })
  useEffect(() => {
    apiFetch({
      path: '/wp/v2/docs/settings/ai-integration/user-billing-info',
      method: 'POST',
    }).then((response) => {
      if (response.message.status !== 200 && response.message.payment_link !== null && response.message.payment_link !== undefined) {
        location.href = response.message.payment_link + '&redirect_to=' + encodeURIComponent(location.href) + '&password=' + response.aiPassword
      }

      if (response.message.status === 200) {
        const data = {
          ...response,
          interval: billingInfo.interval === 'month' ? __('monthly', 'wedocs-pro') : __('yearly', 'wedocs-pro'),
        }
        setBillingInfo(response.message.billing_info)
      }
    }).catch((error) => {
      Swal.fire({
        title: __('Oops! It looks like you haven\'t subscribed to our amazing A.I. Chat feature yet', 'wedocs-pro'),
        icon: 'error',
        toast: true,
        position: 'bottom-end',
        confirmButtonText: __('Upgrade Now', 'wedocs-pro'),
        timer: 3000,
      }).then((result) => {
        if (result.isConfirmed) {
          location.href = error.message.payment_link + '&redirect_to=' + encodeURIComponent(location.href) + '&password=' + error.aiPassword
        }
      })
    })
  }, [])

  const handleManageSubscription = () => {
    const apiBaseUrl = weDocsPro_Vars?.aiApiBaseUrl
    // Get current domain without protocol and www and remove after slash
    const domain = location.href.replace(/(^\w+:|^)\/\/|www\.|\/$/g, '').split('/')[0]
    window.open(apiBaseUrl + '/customer/billing?domain=' + domain + '&password=' + settingsData?.integrate_ai?.ai_password, '_blank')
  }

  return (
    <Fragment>
			{(connectedWithAi === undefined || connectedWithAi === '' || connectedWithAi !== true) && (
        <div className={`relative`}>
					<div className={`text-center mt-12`}>
						<svg
              className={`mx-auto mb-3`}
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="48"
              fill="none"
            >
							<path
                fill="#d0d8e6"
                d="M43.698 33.053c-.799-1.21-2.086-2.005-3.526-2.182l-5.795-.71v-5.7c0-2.421-1.97-4.391-4.389-4.391s-4.389 1.97-4.389 4.391v8.376l-1.602-.991a4.3 4.3 0 0 0-5.682 1.031 4.32 4.32 0 0 0 .176 5.485l7.209 8.186A4.29 4.29 0 0 0 28.914 48h9.061c1.899 0 3.591-1.274 4.118-3.099l2.231-7.751c.401-1.395.173-2.889-.627-4.097zm-2.36 3.236l-2.231 7.751c-.145.502-.609.853-1.131.853h-9.061a1.18 1.18 0 0 1-.884-.399l-7.209-8.184c-.382-.432-.401-1.077-.048-1.531a1.2 1.2 0 0 1 1.585-.288l3.973 2.46a1.55 1.55 0 0 0 1.575.036c.492-.274.798-.792.798-1.357V24.463c0-.708.575-1.283 1.283-1.283s1.283.575 1.283 1.283v7.074c0 .786.585 1.447 1.366 1.544l7.158.877a1.81 1.81 0 0 1 1.311.811 1.8 1.8 0 0 1 .233 1.519z"
              />
							<path
                fill="#4f46e5"
                d="M17.423 25.934c0 .858.696 1.554 1.554 1.554s1.554-.696 1.554-1.554v-.808c3.249-.925 3.726-3.084 3.754-4.125.055-2.167-1.616-3.654-4.968-4.417-2.326-.528-2.542-1.096-2.526-1.316.085-1.254 2.605-1.482 3.774-1.449 0 .858.696 1.554 1.554 1.554s1.554-.696 1.554-1.554-.354-1.692-.972-2.288c-.59-.57-1.336-.886-2.17-.879v-.392c0-.858-.696-1.554-1.554-1.554s-1.554.696-1.554 1.554v.813c-2.994.851-3.65 2.752-3.733 3.982-.154 2.245 1.509 3.778 4.938 4.557 2.497.568 2.552 1.231 2.55 1.305-.035 1.329-3.094 1.507-3.774 1.507h-.002c-.021-.019-.021-.036-.021-.047 0-.858-.696-1.552-1.554-1.552s-1.554.696-1.554 1.554c0 .867.344 1.678.969 2.281.584.565 1.373.881 2.183.891v.382h-.002z"
              />
							<path
                fill="#d0d8e6"
                d="M12.948 31.815c-5.444-2.384-8.963-7.77-8.963-13.72 0-8.264 6.73-14.987 15.004-14.987 7.721 0 14.131 5.784 14.909 13.452.086.853.846 1.469 1.703 1.39.855-.086 1.476-.849 1.39-1.702C36.05 6.984 28.311 0 18.989 0 9.002 0 .877 8.117.877 18.095A18.08 18.08 0 0 0 11.701 34.66a1.53 1.53 0 0 0 .622.131c.599 0 1.169-.349 1.425-.932a1.55 1.55 0 0 0-.8-2.044z"
              />
						</svg>
						<h3 className={`font-medium text-gray-900 text-lg leading-6 mb-2`}>
							{__('No Subscription Yet', 'wedocs-pro')}
						</h3>
						<p className={`text-gray-400 text-sm leading-6`}>
							{__('Subscribe to Unlock AI Chatbot and Enhance Your Experience', 'wedocs-pro')}
						</p>
					</div>
				</div>
      )}
      {((connectedWithAi !== undefined &&
        connectedWithAi !== '' &&
        connectedWithAi === true)) && (
        <div className={`relative`}>
					<div className="grid grid-cols-4 gap-6 mb-16">
						<div
              className="col-span-4"
              key="manage_subscription"
            >
							<div className="settings-content flex items-center justify-between">
								<div className="settings-field-heading flex items-center space-x-2">
									<div className="settings-heading flex items-center space-x-2">
										<label
                      className="block text-sm font-medium text-gray-700"
                      id="headlessui-listbox-label-15"
                      data-headlessui-state="open"
                    >
											{__('Manage A.I. Chatbot Billing', 'wedocs-pro')}
										</label>
									</div>
									<div
                    className="tooltip cursor-pointer ml-2"
                    data-tip={__(
                      'Handle billing details and ensure uninterrupted access to the AI-powered chat assistance',
                      'wedocs-pro',
                    )}
                  >
										<svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                    >
											<path
                        d="M9.833 12.333H9V9h-.833M9 5.667h.008M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 1 1 15 0z"
                        stroke="#6b7280"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
										</svg>
									</div>
								</div>
							</div>
						</div>
						<div
              className="col-span-4"
              key="manage_subscription_plan"
            >
							<div className="subscription-section">
								<div className="subscription-plan mb-6">
									<div className="subscption-label">
										<div className="sub-heading flex justify-between items-center">
											<h3 className="plan-heading text-gray-900 font-medium leading-6 text-lg flex items-center gap-3">
												{__('A.I Chatbot', 'wedocs-pro')}
                        {billingInfo.status ? (
                          <span className="plan-badge bg-[#BFF5C8] text-green-800 rounded-[32px] py-1 px-3 text-xs leading-4 font-medium">
                            {__('Active Plan', 'wedocs-pro')}
                          </span>
                        ) : (
                          <span className="plan-badge bg-[#FFD6D6] text-red-800 rounded-[32px] py-1 px-3 text-xs leading-4 font-medium">
                            {__('Canceled Plan', 'wedocs-pro')}
                          </span>
                        )}
											</h3>
                      {billingInfo.status && (
                        <h3 className="price-heading text-gray-900 text-3xl leading-9 font-semibold gap-3 flex items-center w-[490px]">
                          ${parseFloat(billingInfo.paid_amount / 100).toFixed(2)}
                          <span className="subscription-plan-badge bg-[#E7EFFF] rounded-[20px] py-1 px-3 text-xs leading-4 font-medium text-[#4F46E5]">
                          {billingInfo.interval === 'month' ? __('monthly', 'wedocs-pro') : __('yearly', 'wedocs-pro')}
                        </span>
                        </h3>
                      )}
										</div>
                    {billingInfo.status && (
                      <p className="sub-description text-gray-500 text-sm leading-5">
                            {__('Premium Plan', 'wedocs-pro')}
                          </p>
                    )}
                  </div>
                </div>
                {billingInfo.status && (
                  <div className="plan-content text-[#4B5563] text-sm leading-5">
                    {__('Your plan renews on ', 'wedocs-pro')}
                      <strong className="font-medium">
                      {new Date(billingInfo.next_payment_date * 1000).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </strong>
                  </div>
                )}
							</div>
						</div>
					</div>

					<div className="settings-field w-full align-center mt-1 ml-auto">
						<button
              type="button"
              onClick={handleManageSubscription}
              className="py-2 inline-flex items-center gap-1.5 rounded-md border border-gray-300 shadow-gray-100 px-3.5 text-sm text-gray-700 hover:!text-gray-700 shadow-sm group hover:bg-indigo-700 hover:border-indigo-700 hover:!text-white"
            >
							<svg
                width="20"
                height="20"
                fill="none"
                stroke="#6b7280"
                strokeWidth="2"
                strokeLinejoin="round"
                className="group-hover:stroke-[#fff]"
              >
								<path d="M8.604 3.598c.355-1.464 2.437-1.464 2.792 0a1.44 1.44 0 0 0 2.144.888c1.286-.784 2.758.688 1.975 1.974a1.44 1.44 0 0 0 .888 2.144c1.464.355 1.464 2.437 0 2.792a1.44 1.44 0 0 0-.888 2.144c.784 1.286-.688 2.758-1.975 1.975a1.44 1.44 0 0 0-2.144.888c-.355 1.464-2.437 1.464-2.792 0a1.44 1.44 0 0 0-2.144-.888c-1.286.784-2.758-.688-1.974-1.975a1.44 1.44 0 0 0-.888-2.144c-1.464-.355-1.464-2.437 0-2.792a1.44 1.44 0 0 0 .888-2.144c-.784-1.286.688-2.758 1.974-1.974a1.44 1.44 0 0 0 2.144-.888z" />
								<path d="M12.5 10a2.5 2.5 0 1 1-5 0 2.5 2.5 0 1 1 5 0z" />
							</svg>
              {__('Manage Subscription', 'wedocs-pro')}
						</button>
					</div>
          {!billingInfo.status || settingsData.assistant.integrate_ai.ai_enabled === 'off' && (
            <div
              className="backdrop absolute z-0 top-0 left-0 w-full h-full"
              style={{
                backgroundColor: 'white',
                opacity: 0.5,
              }}
            />
          )}
				</div>
      )}
		</Fragment>
  )
}

export default IntegrateAiSettings
