import PrivacyModal from './Modals/PrivacyModal';

const PrivacyIcon = ( {
	docId,
	setDocStatus,
	isArticle,
	removeRestriction,
} ) => {
	return (
		<PrivacyModal
			docId={ docId }
			isArticle={ isArticle }
			setDocStatus={ setDocStatus }
			removeRestriction={ removeRestriction }
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="hover:cursor-pointer"
				width="15"
				height="16"
				fill="none"
			>
				<path
					fillRule="evenodd"
					d="M2.951 7V5a5 5 0 1 1 10 0v2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2zm8-2v2h-6V5a3 3 0 0 1 6 0z"
					fill="#6b7280"
				/>
			</svg>
		</PrivacyModal>
	);
};

export default PrivacyIcon;
