import { __ } from '@wordpress/i18n';
import Appearance from './Appearance';
import AssistantPanel from '../AssistantPanel';
import PanelBody from '../PanelBody';
import MessageEnable from './MessageEnable';
import MessageSettings from './MessageSettings';

const MessagePanel = ( { settingsData, setSettings } ) => {

	const handleMessagingContent = ( e, field ) => {
		setSettings( {
			...settingsData,
			assistant: {
				...settingsData?.assistant,
				message: {
					...settingsData?.assistant?.message,
					[ field ]: e.target.value,
				},
			},
		} );
	};

	const tabs = [
		__( 'Messaging', 'wedocs-pro' ),
		__( 'Appearance', 'wedocs-pro' ),
	];

	const panels = [
		<MessageSettings
			settingsData={ settingsData }
			handleMessagingContent={
				handleMessagingContent
			}
		/>,
		<Appearance
			settingsData={ settingsData }
			setSettings={ setSettings }
			handleMessagingContent={
				handleMessagingContent
			}
		/>
	];

	return (
		<AssistantPanel
			settingsData={ settingsData }
			setSettings={ setSettings }
		>
			<PanelBody
				tabs={ tabs }
				panels={ panels }
			>
				<MessageEnable
					settingsData={ settingsData }
					setSettings={ setSettings }
				/>
			</PanelBody>
		</AssistantPanel>
	);
}

export default MessagePanel;
