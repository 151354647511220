import { __ } from '@wordpress/i18n';
import Swal from 'sweetalert2';

export function getCurrentPageId() {
	let pageId = '';
	const pageBody = document.querySelector( 'body.page' );

	if ( pageBody ) {
		const classList = pageBody.getAttribute( 'class' );
		const matches = classList.match( /page-id-(\d+)/ );
		pageId = parseInt( matches?.[1] );
	}

	return pageId;
}

export function wpMedia( {
	callback,
	title = __( 'Select or upload media.', 'wedocs-pro' ),
	selectMultiple = false,
	fileType = 'image',
	dimension = {},
} ) {
	const frame = wp.media( {
		title,
		library: {
			type: fileType,
		},
		multiple: selectMultiple, // Set to true to allow multiple files to be selected
	} );

	frame.open();

	// When an file is selected, run a callback.
	frame.on( 'select', () => {
		const attachment = frame.state().get( 'selection' ).first().toJSON();
		if ( attachment?.type !== fileType ) {
			Swal.fire( {
				title: __( 'Upload Failed', 'wedocs-pro' ),
				text: __( 'The attachment type is not matched.', 'wedocs-pro' ),
				icon: 'error',
				toast: true,
				position: 'bottom-end',
				showConfirmButton: false,
				timer: 3000,
			} );
			return;
		}

		if ( attachment?.type === 'image' ) {
			const imgWidth = attachment.width,
				imgHeight = attachment.height;

			if ( dimension?.width < imgWidth && dimension.height < imgHeight ) {
				Swal.fire( {
					title: __( 'Upload Failed', 'wedocs-pro' ),
					text: __(
						'The image dimensions are too big. The maximum size is 20x20 pixels.',
						'wedocs-pro'
					),
					icon: 'error',
					toast: true,
					position: 'bottom-end',
					showConfirmButton: false,
					timer: 3000,
				} );

				return;
			}
		}

		callback( attachment );
	} );
}

export function isAdminUser() {
	return weDocsPro_Vars?.hasManageCap;
}

export function getCurrentUserId() {
	return weDocsPro_Front_Vars?.currentUserId || null;
}

export function isUserCan( capability ) {
	return weDocsPro_Front_Vars.userCapabilities[capability] || false;
}
