import { Link } from 'react-router-dom';
import { __ } from '@wordpress/i18n';
import { useSelect } from '@wordpress/data'

const ViewDoc = ({docId}) => {
  const doc = useSelect(
    ( select ) => select( 'wedocs/docs' ).getDoc( parseInt( docId ) ),
    []
  );
	return (
		<Link
			to={doc.link}
      target="_blank"
			className="inline-flex no-underline group items-center text-gray-900 focus:shadow-none !text-sm border border-gray-300 px-2 py-1.5 rounded-md hover:border-indigo-600"
		>
        <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
          <path d="M11.6995 7.14388C11.6995 8.52459 10.5802 9.64388 9.1995 9.64388C7.81879 9.64388 6.6995 8.52459 6.6995 7.14388C6.6995 5.76317 7.81879 4.64388 9.1995 4.64388C10.5802 4.64388 11.6995 5.76317 11.6995 7.14388Z" stroke="#374151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M1.24805 7.14385C2.30994 3.76294 5.46851 1.31055 9.19987 1.31055C12.9312 1.31055 16.0898 3.76298 17.1517 7.14391C16.0898 10.5248 12.9312 12.9772 9.19989 12.9772C5.46851 12.9772 2.30992 10.5248 1.24805 7.14385Z" stroke="#374151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        { __("View Doc", 'wedocs-pro') }
		</Link>
	);
};

export default ViewDoc;
