import { __ } from '@wordpress/i18n';
import IntegrateAiSettings from './IntegrateAiSettings';
import Appearance from './Appearance';
import AssistantPanel from '../AssistantPanel';
import PanelBody from '../PanelBody';
import AiEnable from './AiEnable';
import { useEffect, useState } from '@wordpress/element';
import apiFetch from '@wordpress/api-fetch';

const IntegrateAi = ( { settingsData, setSettings } ) => {
  const [ resetUser, setResetUser ] = useState( false );
  const [ connectedWithAi, setConnectedWithAi ] = useState( settingsData?.integrate_ai?.connected_with_ai );
  const tabs = [
    __( 'A.I. Chat', 'wedocs-pro' ),
    __( 'Appearance', 'wedocs-pro' ),
  ];

  const resetAI = () => {
    apiFetch( {
      path: '/wp/v2/docs/settings/ai-integration/reset-ai',
      method: 'POST',
    }).then( ( response ) => {
      location.reload();
      setResetUser( true );
    })
  };

  useEffect( () => {
    if( connectedWithAi === undefined ||
      connectedWithAi === '' ||
      connectedWithAi !== true ){
      apiFetch( {
        path: '/wp/v2/docs/settings/ai-integration',
        method: 'POST',
      }).then( ( response ) => {
        if ( response.message.status === 200 ) {
          setConnectedWithAi( true )
        }
      }).catch( ( error ) => {
        console.log( error )
      })
    }
  }, [ resetUser ] )

  const panels = [
    <IntegrateAiSettings
      settingsData={ settingsData }
      connectedWithAi={ connectedWithAi }
    />,
    <Appearance
      settingsData={ settingsData }
      setSettings={ setSettings }
    />
  ];

  return (
    <AssistantPanel
      settingsData={ settingsData }
      setSettings={ setSettings }
    >
			<PanelBody
        tabs={ tabs }
        panels={ panels }
      >
				<AiEnable
          resetAI={ resetAI }
          setSettings={ setSettings }
          settingsData={ settingsData }
          connectedWithAi={ connectedWithAi }
        />
			</PanelBody>
		</AssistantPanel>
  );
}

export default IntegrateAi;
