import Bubble from './Bubble';
import {
	createHashRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from 'react-router-dom';
import { createRoot, Fragment } from '@wordpress/element';

const mountDiv = document.createElement( 'div' );
mountDiv.setAttribute( 'id', 'wedocs-messaging-bubble-root' );
document.body.appendChild( mountDiv );

const messagingAppDom = document.getElementById(
	'wedocs-messaging-bubble-root'
);

let routes = [
	{ path: '/', component: Bubble },
	{ path: '/:id', component: Bubble },
	{ path: '/*', component: Bubble },
];

const router = createHashRouter(
	createRoutesFromElements(
		<Fragment>
			{ routes &&
				routes.map( ( route, index ) => (
					<Route
						key={ index }
						path={ route.path }
						element={ <route.component /> }
						errorElement={ <route.component /> }
					/>
				) ) }
		</Fragment>
	)
);

if ( messagingAppDom ) {
	const root = createRoot( messagingAppDom );
	root.render( <RouterProvider router={ router } /> );
}
