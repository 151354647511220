import { Link } from 'react-router-dom';
import { __ } from '@wordpress/i18n';

const BackToListing = () => {
	return (
		<Link
			to="/"
			className="inline-flex no-underline group items-center text-gray-900 hover:text-indigo-600 focus:shadow-none !text-sm"
		>
			<svg
				width="35"
				height="10"
				viewBox="0 0 20 10"
				className="pr-1.5 group-hover:!stroke-indigo-500 stroke-gray-500"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M5 9L1 5M1 5L5 1M1 5L19 5"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
			{ __( 'Back', 'wedocs-pro' ) }
		</Link>
	);
};

export default BackToListing;
