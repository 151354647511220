const MessageIcon = ( { classes, show, setShow, previewColors } ) => {
	const { bubbleBg, bubbleIcon } = previewColors;

	const handleClick = () => {
		setShow( ! show );
	};

	return (
		<div
			onClick={ handleClick }
			className={ `${
				classes ? classes : ''
			} msg-icon xs:w-12 xs:h-12 w-16 h-16 rounded-full ml-auto flex items-center justify-center` }
			style={ {
				background: `rgba(${ bubbleBg?.r }, ${ bubbleBg?.g }, ${ bubbleBg?.b }, ${ bubbleBg?.a })`,
			} }
		>
			{ show ? (
				<svg
					width="22"
					height="22"
					xmlns="http://www.w3.org/2000/svg"
					className="w-6 h-6 text-white rotate-180 transition-all cancel-svg"
					style={ {
						fill: `rgba(${ bubbleIcon?.r }, ${ bubbleIcon?.g }, ${ bubbleIcon?.b }, ${ bubbleIcon?.a })`,
					} }
				>
					<path d="M2.45.42A1.435 1.435 0 0 0 .42 2.45L8.97 11 .42 19.55a1.435 1.435 0 1 0 2.03 2.03L11 13.03l8.55 8.55a1.435 1.435 0 1 0 2.03-2.03L13.03 11l8.55-8.55A1.435 1.435 0 0 0 19.55.42L11 8.97 2.45.42Z" />
				</svg>
			) : (
				<svg
					width="25"
					height="24"
					xmlns="http://www.w3.org/2000/svg"
					className="rotate-360 transition-all"
					style={ {
						fill: `rgba(${ bubbleIcon?.r }, ${ bubbleIcon?.g }, ${ bubbleIcon?.b }, ${ bubbleIcon?.a })`,
					} }
				>
					<path d="M2.157 23.042a1.2 1.2 0 0 1-1.198-1.187V6.481A5.91 5.91 0 0 1 6.871.569h11.835a5.91 5.91 0 0 1 5.912 5.912v7.099a5.91 5.91 0 0 1-5.912 5.912H7.27L2.833 22.82c-.198.141-.434.219-.677.222zM6.882 2.943a3.55 3.55 0 0 0-3.549 3.549v13l2.84-2.13c.205-.152.454-.234.71-.233h11.824a3.55 3.55 0 0 0 3.55-3.549V6.481a3.55 3.55 0 0 0-3.55-3.549l-11.824.011zm8.264 5.934H8.058c-.165.016-.331-.003-.488-.055s-.302-.136-.424-.248-.221-.247-.288-.398-.102-.315-.102-.481.035-.329.102-.481.165-.287.288-.398.267-.196.424-.248.323-.071.488-.055h7.088c.165-.016.331.003.488.055s.302.136.424.248.221.247.288.398.102.315.102.481-.035.329-.102.481-.165.287-.288.398a1.19 1.19 0 0 1-.424.248c-.157.052-.323.071-.488.055zm-7.088 4.725h3.549c.165.016.331-.003.488-.055s.302-.136.424-.248.22-.247.287-.398a1.19 1.19 0 0 0 0-.961c-.067-.151-.165-.287-.287-.398s-.267-.196-.424-.248-.323-.071-.488-.055H8.058c-.165-.016-.331.003-.488.055s-.302.136-.424.248-.221.247-.288.398-.102.315-.102.481.035.329.102.481.165.287.288.398.267.196.424.248.323.071.488.055z" />
				</svg>
			) }
		</div>
	);
};

export default MessageIcon;
