import MessageBody from './MessageBody';
import MessageIcon from './MessageIcon';
import { useEffect, useState } from '@wordpress/element';

const MessagePreview = ( {
	classes,
	settings,
	bodyClasses,
	iconClasses,
	previewColors,
	staticPreview,
	frontendPreview,
} ) => {
	const [ docId, setDocId ] = useState( 0 );
	const [ show, setShow ] = useState( false );
	const [ showDocContent, setShowDocContent ] = useState( false );

	const widgetLinks = document.querySelectorAll( 'a[rel~="widget-link"]' );
	widgetLinks?.forEach( linkDom => {
		linkDom.addEventListener( 'click', ( event ) => {
			setDocId( event?.target?.getAttribute('data-id') );
			setShow( true );
			setShowDocContent( true );
		} );
	} );

	useEffect( () => {
		if ( ! show ) setShowDocContent( false );
	}, [ show ] );

	return (
		<>
			{ previewColors && (
				<div className={ `${ classes ? classes : '' } palette-preview-container relative` }>
					{ staticPreview ? (
						<MessageBody
							settings={ settings }
							classes={ bodyClasses }
							previewColors={ previewColors }
						/>
					) : (
						show && (
							<MessageBody
								docId={ docId }
								settings={ settings }
								classes={ bodyClasses }
								previewColors={ previewColors }
								showDocContent={ showDocContent }
								frontendPreview={ frontendPreview }
								setShowDocContent={ setShowDocContent }
							/>
						)
					) }

					<MessageIcon
						setShow={ setShow }
						settings={ settings }
						classes={ iconClasses }
						previewColors={ previewColors }
						show={ staticPreview ? false : show }
					/>
				</div>
			) }
		</>
	);
};

export default MessagePreview;
