import { __ } from '@wordpress/i18n';
import PrivacyIcon from '../PrivacyIcon';
import { useSelect } from '@wordpress/data';
import { useEffect, useState } from '@wordpress/element';

const ArticleRestrictionIcon = ( { id } ) => {
	const [ isAdminRestricted, setIsAdminRestricted ] = useState( 0 );

	const restrictedArticles = useSelect(
		( select ) => select( 'wedocs/docs' ).getRestrictedArticles(),
		[]
	);

	useEffect( () => {
		restrictedArticles?.map( restrictedArticle => {
			if ( restrictedArticle?.id === id ) {
				setIsAdminRestricted( parseInt( restrictedArticle?.value ) );
			}
		} );
	}, [ restrictedArticles ] );

	if ( !Boolean( isAdminRestricted ) ) return;

	return (
		<div
			className="tooltip cursor-pointer ml-2 z-10"
			data-tip={ __(
				'Only admin(s) can edit this document',
				'wedocs-pro'
			) }
		>
			<PrivacyIcon
				key={ id }
				docId={ id }
				isArticle={ true }
				removeRestriction={ true }
			/>
		</div>
	);
}

export default ArticleRestrictionIcon;
