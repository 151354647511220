import './assets/css/index.css';
import { __ } from '@wordpress/i18n';
import ManagerPage from './components/DocManager';
import PrivacyIcon from './components/PrivacyIcon';
import PermissionSettings from './components/Settings/PermissionSettings';
import MessagePreview from './components/Settings/AssistantWidgetPanels/MessagePreview';
import { dispatch, resolveSelect, useSelect } from '@wordpress/data';
import PrivacyModal from './components/Modals/PrivacyModal';
import { useEffect, useState } from '@wordpress/element';
import apiFetch from '@wordpress/api-fetch';
import { isAdminUser } from './utils/helper';
import LayoutSettings from './components/Settings/LayoutSettings';
import AiChatBotSettings from './components/Settings/AssistantWidgetPanels/AiChatPanel';
import ExploreSettings from './components/Settings/AssistantWidgetPanels/ExplorePanel';
import MessageSettings from './components/Settings/AssistantWidgetPanels/MessagePanel';
import PreferenceSettings from './components/Settings/AssistantWidgetPanels/PreferencePanel';
import PlacementSettings from './components/Settings/AssistantWidgetPanels/PlacementPanel';
import ArticleContributors from './components/Documentation/ArticleContributors';
import ArticleRestrictionIcon from './components/Documentation/ArticleRestrictionIcon';
import ArticleRestrictionMenu from './components/Documentation/ArticleRestrictionMenu';

wp.hooks.addFilter(
	'wedocs_pro_loaded',
	'wedocs_pro_loaded_callback',
	function () {
		return true;
	}
);

wp.hooks.addFilter(
	'wedocs_admin_documentation_action_menu_width',
	'wedocs_admin_documentation_action_menu_width_callback',
	function () {
		return 'w-[200px]';
	}
);

wp.hooks.addFilter(
	'wedocs_admin_article_action_menu_width',
	'wedocs_admin_article_action_menu_width_callback',
	function () {
		return 'w-60';
	}
);

wp.hooks.addFilter(
	'wedocs_register_menu_routes',
	'wedocs_register_menu_routes_callback',
	function ( routes ) {
		const { weDocsSettingsPage: SettingsPage } = window;

		if ( !weDocsPro_Vars?.hasManageCap ) {
			routes = routes?.filter( route => route?.path !== 'migrate' );
		}

		routes?.push( { path: 'manager/:id', component: ManagerPage } );
		if ( weDocsPro_Vars?.canAccessSettings ) {
			routes?.push( { path: 'settings/:panel', component: SettingsPage } );
		} else {
			routes = routes?.filter( route => route?.path !== 'settings' );
		}

		return routes;
	}
);

wp.hooks.addFilter(
	'wedocs_validate_listing_params',
	'wedocs_validate_listing_params_callback',
	function ( validate, parentDocIds, isAdmin, id ) {
		if ( isAdmin ) {
			return validate;
		}

		return parentDocIds?.includes( parseInt( id ) );
	}
);

wp.hooks.addFilter(
	'wedocs_documentation_privacy_action',
	'wedocs_documentation_privacy_action_callback',
	function ( componentsArray, id ) {
		const [ status, setStatus ] = useState( 'publish' );

		useEffect( () => {
			apiFetch( {
				path: '/wp/v2/docs/' + id,
			} )
				.then( ( result ) => {
					setStatus( result?.status );
				} )
				.catch( ( err ) => {
					console.log( err );
				} );
		}, [] );

		return [
			...componentsArray,
			status === 'private' && (
				<div
          className="tooltip cursor-pointer pr-5 ml-1"
					data-tip={ __(
						'This doc is private',
						'wedocs-pro'
					) }
				>
					<svg
						width="15"
						height="16"
						fill="none"
						className="hover:cursor-pointer"
					>
						<path
							fill="#6b7280"
							fillRule="evenodd"
							d="M2.951 7V5a5 5 0 1 1 10 0v2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2zm8-2v2h-6V5a3 3 0 0 1 6 0z"
						/>
					</svg>
				</div>
			),
		];
	}
);

wp.hooks.addFilter(
	'wedocs_article_privacy_action',
	'wedocs_article_privacy_action_callback',
	function ( componentsArray, id ) {
		return [
			...componentsArray,
			<ArticleRestrictionIcon id={ id } />
		];
	}
);

wp.hooks.addFilter(
	'wedocs_check_is_admin_restricted_article',
	'wedocs_check_is_admin_restricted_article_callback',
	function ( defaultValue, id ) {
		if ( isAdminUser() ) return defaultValue;

		const [ isAdminRestricted, setIsAdminRestricted ] =
			useState( defaultValue );

		// Fetch article admin restriction meta.
		apiFetch( {
			path:
				'/wp/v2/docs/' +
				id +
				'/meta?key=wedocs_restrict_admin_article_access',
		} )
			.then( ( result ) => {
				setIsAdminRestricted( result );
			} )
			.catch( ( err ) => {} );

		return isAdminRestricted;
	}
);

wp.hooks.addFilter(
	'wedocs_admin_article_restriction_action',
	'wedocs_admin_article_restriction_action_callback',
	function ( componentsArray, id, type ) {
		return <ArticleRestrictionMenu id={ id } type={ type } />;
	}
);

wp.hooks.addFilter(
	'wedocs_documentation_fetching_path',
	'wedocs_documentation_fetching_path_callback',
	( docFetchingPath ) => {
		return docFetchingPath + ',private';
	}
);

wp.hooks.addAction(
	'wedocs_after_create_new_doc',
	'wedocs_after_create_new_doc_callback',
	( newDoc ) => {
		if ( isAdminUser() ) {
			return;
		}

		const roleCapSettings = {};
		{ Object.entries( weDocsPro_Vars?.siteRoles )?.forEach( role => {
			roleCapSettings[ role?.[0] ] = weDocsPro_Vars?.currentUserRoles?.includes( role?.[0] ) ? 'edit' : 'view';
		} ) }

		const permissionMeta = {
			key   : 'wedocs_user_permission',
			value : 'custom',
		};

		const roleCapSettingsMeta = {
			key   : 'wedocs_access_role_capabilities',
			value : roleCapSettings,
		}

		// Update documentation permission meta.
		dispatch( 'wedocs/docs' )
			.updateDocMeta( newDoc?.id, permissionMeta )
			.then( ( result ) => {} )
			.catch( ( err ) => {} );

		// Update documentation role capabilities meta.
		dispatch( 'wedocs/docs' )
			.updateDocMeta( newDoc?.id, roleCapSettingsMeta )
			.then( ( result ) => {} )
			.catch( ( err ) => {} );
	}
);

wp.hooks.addAction(
	'wedocs_after_filter_documentations',
	'wedocs_filter_documentations_callback',
	( docs, isAdmin ) => {
		if ( isAdmin ) {
			return;
		}

		resolveSelect( 'wedocs/docs' ).getUserDocIds().then( ids => {
			let filteredDocs = docs?.filter( ( doc ) => ids?.includes( doc?.id ) );
			if ( docs.length === filteredDocs.length ) {
				return;
			}

			dispatch( 'wedocs/docs' ).setParentDocs( filteredDocs );
		} );
	}
);

wp.hooks.addFilter(
	'wedocs_show_documentation_empty_label',
	'wedocs_show_documentation_empty_label_callback',
	( label, docLength ) => {
		return (
			<>
				{ ! Boolean( isAdminUser() ) && docLength === 0 ? (
					<h2 className="float-left text-lg mt-4">
						{ __(
							'No documentation is available for you now…',
							'wedocs-pro'
						) }
					</h2>
				) : (
					label
				) }
			</>
		);
	}
);

wp.hooks.addFilter(
	'wedocs_documentation_contributors',
	'wedocs_documentation_contributors_callback',
	function ( content, id ) {
		if ( ! isAdminUser() ) return;

		const [ userAvatars, setUserAvatars ] = useState( [] );
		const [ contributors, setContributors ] = useState( [] );

		useEffect( () => {
			apiFetch( {
				path: '/wp/v2/docs/' + id + '/meta?key=wedocs_contributors',
			} )
				.then( ( result ) => {
					setContributors( [ ...contributors, ...result ] );
				} )
				.catch( ( err ) => {
					console.log( err );
				} );
		}, [ id ] );

		useEffect( () => {
			contributors?.map( ( contributorId ) => {
				apiFetch( {
					path: '/wp/v2/users/' + contributorId,
				} )
					.then( ( result ) => {
						const userName = result?.name,
							avatarUrl = result?.avatar_urls?.[ '24' ];

						userAvatars.push( {
							name: userName,
							url: avatarUrl,
						} );

						setUserAvatars( [ ...userAvatars ] );
					} )
					.catch( ( err ) => {
						console.log( err );
					} );
			} );
		}, [ contributors ] );

		return (
			<div className="isolate ml-4 flex -space-x-2 h-7">
				{ userAvatars &&
					userAvatars?.map( ( avatar, index ) =>
						index < 4 ? (
							<div
								key={ index }
								className="tooltip cursor-pointer"
								data-tip={ __(
									avatar?.name,
									'wedocs-pro'
								) }
							>
								<img
									src={ avatar?.url }
									alt={ avatar?.name }
									className="relative z-0 inline-block h-full w-7 rounded-full ring-2 ring-white"
								/>
							</div>
						) : (
							''
						)
					) }

				{ userAvatars && userAvatars?.length > 4 && (
					<div className="relative z-0 inline-block h-full w-7 rounded-full ring-2 ring-white bg-indigo-700 text-white">
						<span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-[1px]">
							+{ userAvatars?.length - 4 }
						</span>
					</div>
				) }
			</div>
		);
	}
);

wp.hooks.addFilter(
	'wedocs_article_contributors',
	'wedocs_article_contributors_callback',
	function ( content, id ) {
		if ( ! isAdminUser() ) return;

		return ( <ArticleContributors id={ id } /> );
	}
);

wp.hooks.addFilter(
	'wedocs_settings_menu',
	'settings_menu_override',
	function ( menus ) {
		menus.permission = {
			text: __( 'Permission Management', 'wedocs-pro' ),
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					fill="none"
					stroke="#6b7280"
					strokeWidth="2"
					strokeLinejoin="round"
					className="-ml-1 mr-4 settings-menu"
				>
					<path d="M10 2.866a3.99 3.99 0 0 1 3-1.354 4 4 0 1 1 0 8 3.99 3.99 0 0 1-3-1.354m3 11.354H1v-1a6 6 0 1 1 12 0v1zm0 0h6v-1a6 6 0 0 0-6-6 5.97 5.97 0 0 0-3 .803m1-7.803a4 4 0 1 1-8 0 4 4 0 1 1 8 0z" />
				</svg>
			),
		};
		menus.assistant = {
			text: __( 'Assistant Widget', 'wedocs-pro' ),
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					fill="none"
					stroke="#6b7280"
					strokeWidth="2"
					strokeLinejoin="round"
					className="-ml-1 mr-4 settings-menu"
				>
					<path d="M1 3.512v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-6l-2-2H3a2 2 0 0 0-2 2z" />
				</svg>
			),
			disabled: true,
			subtabs: [
				{
					text: __( 'A.I. Chat', 'wedocs-pro' ),
					icon: (
						<svg width="20" height="16" fill="none" style={{ marginRight: '1em' }} className="-ml-1 mr-4">
							<path fillRule="evenodd" d="M1.703.818a1.02 1.02 0 0 1 1.022 1.022 1.02 1.02 0 0 1-.606.934v3.032l.165-.017C3.033 2.635 5.979.818 9.5.818s6.466 1.816 7.216 4.97l.165.014V2.775a1.02 1.02 0 0 1-.606-.934c0-.564.458-1.022 1.022-1.022a1.02 1.02 0 0 1 1.022 1.022 1.02 1.02 0 0 1-.606.934v3.245c.544.249.911.702.949 1.405l.003.568-.001.768c-.057 1.063-.862 1.557-1.897 1.638-.65 3.305-3.658 5.219-7.269 5.219s-6.62-1.914-7.269-5.221C1.195 10.3.392 9.72.337 8.659l-.003-.666.003-.466a1.68 1.68 0 0 1 .949-1.472v-3.28a1.02 1.02 0 0 1-.605-.934c0-.564.458-1.022 1.022-1.022zm13.566 6.45l.009.951-.01.939c-.16 3.046-2.682 4.837-5.768 4.837s-5.607-1.79-5.768-4.837l-.008-.939.009-.951c.166-3.042 2.685-4.826 5.768-4.826s5.601 1.784 5.768 4.826zM13.17 7.17c0-.602-.486-1.088-1.088-1.088s-1.088.486-1.088 1.088.486 1.088 1.088 1.088 1.088-.486 1.088-1.088zm-5.167 0a1.09 1.09 0 0 1-1.088 1.088A1.09 1.09 0 0 1 5.827 7.17a1.09 1.09 0 0 1 1.088-1.088A1.09 1.09 0 0 1 8.003 7.17z" style={{fill: '#6b7280'}}></path>
						</svg>
					),
				},
				{
					text: __( 'Explore', 'wedocs-pro' ),
					icon: (
						<svg width='20' height='20' fill='none' className="-ml-1 mr-4">
							<path
								strokeWidth='2'
								stroke='#6b7280'
								strokeLinecap='round'
								className="-ml-1 mr-4"
								d='M6 19h7a2 2 0 0 0 2-2V7.414a1 1 0 0 0-.293-.707L9.293 1.293A1 1 0 0 0 8.586 1H3a2 2 0 0 0-2 2v11m0 5l4.879-4.879m0 0A2.99 2.99 0 0 0 8 15a3 3 0 1 0 0-6 3 3 0 0 0-3 3 2.99 2.99 0 0 0 .879 2.121z'
							/>
						</svg>
					),
				},
				{
					text: __( 'Messaging', 'wedocs-pro' ),
					icon: (
						<svg width='20' height='16' fill='none' className="-ml-1 mr-4">
							<path
								d='M1 4l7.891 5.26a2 2 0 0 0 2.219 0L19 4M3 15h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2z'
								strokeWidth='2'
								stroke='#6b7280'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					),
				},
				{
					text: __( 'Placement', 'wedocs-pro' ),
					icon: (
						<svg width='20' height='14' fill='none' className='-ml-1 mr-4'>
							<path
								strokeWidth='2'
								stroke='#6b7280'
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M1 1H17M1 7H17M1 13H8'
							/>
						</svg>
					),
				},
				{
					text: __( 'Widget Preference', 'wedocs-pro' ),
					icon: (
						<svg width='20' height='18' fill='none' className='-ml-1 mr-4'>
							<path
								strokeWidth='2'
								stroke='#6b7280'
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M9 3V1m0 2a2 2 0 1 0 0 4m0-4a2 2 0 1 1 0 4m-6 8a2 2 0 1 0 0-4m0 4a2 2 0 1 1 0-4m0 4v2m0-6V1m6 6v10m6-2a2 2 0 1 0 0-4m0 4a2 2 0 1 1 0-4m0 4v2m0-6V1'
							/>
						</svg>
					),
				},
			],
		};
		menus.styles = {
			text: __( 'Layout & Styles', 'wedocs-pro' ),
			icon: (
				<svg
					width="20"
					height="20"
					fill="none"
					strokeWidth="2"
					stroke="#6b7280"
					strokeLinejoin="round"
					className="-ml-0.5 mr-4 settings-menu"
				>
					<path d="M1 2.47a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm0 8a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-6zm12 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-6z" />
				</svg>
			),
		};

		return menus;
	}
);

wp.hooks.addFilter(
	'wedocs_settings_page_templates',
	'wedocs_settings_page_templates_callback',
	function ( templates, docSettings, setDocSettings, index ) {
		const assistantWidgetSubPanels = [
			<AiChatBotSettings
				key={ index }
				settingsData={ docSettings }
				setSettings={ setDocSettings }
			/>,
			<ExploreSettings
				key={ index }
				settingsData={ docSettings }
				setSettings={ setDocSettings }
			/>,
			<MessageSettings
				key={ index }
				settingsData={ docSettings }
				setSettings={ setDocSettings }
			/>,
			<PlacementSettings
				key={ index }
				settingsData={ docSettings }
				setSettings={ setDocSettings }
			/>,
			<PreferenceSettings
				key={ index }
				settingsData={ docSettings }
				setSettings={ setDocSettings }
			/>,
		];

		return [
			...templates,
			<PermissionSettings
				key={ index }
				settingsData={ docSettings }
				setSettings={ setDocSettings }
			/>,
			...assistantWidgetSubPanels,
			<LayoutSettings
				key={ index }
				settingsData={ docSettings }
				setSettings={ setDocSettings }
			/>,
		];
	}
);

wp.hooks.addFilter(
	'wedocs_settings_page_footer',
	'wedocs_settings_page_footer_callback',
	function ( value, assistantWidgetSettings ) {
		return (
			<div className="sticky bottom-5">
				<MessagePreview
					settings={ assistantWidgetSettings }
					bodyClasses={ '-translate-y-full absolute right-0 ml-auto' }
					iconClasses={ 'mt-24 cursor-pointer hover:bg-indigo-600' }
				/>
			</div>
		);
	}
);

wp.hooks.addFilter(
	'wedocs_show_parent_documentation_addition_actions',
	'wedocs_show_parent_documentation_addition_actions_callback',
	() => weDocsPro_Vars?.hasGlobalDocEditAccess
);
