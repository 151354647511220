import AssistantPanel from '../AssistantPanel';
import PlacementSettings from './PlacementSettings';

const PreferencePanel = ( { settingsData, setSettings } ) => {
	return (
		<AssistantPanel
			settingsData={ settingsData }
			setSettings={ setSettings }
		>
			<PlacementSettings
				settingsData={ settingsData }
				setSettings={ setSettings }
			/>
		</AssistantPanel>
	);
}

export default PreferencePanel;
