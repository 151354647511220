import BackToListing from './BackToListing';
import { useSelect } from '@wordpress/data';
import ViewDoc from './ViewDoc'

const SingleDoc = ( { docId } ) => {
	const doc = useSelect(
		( select ) => select( 'wedocs/docs' ).getDoc( parseInt( docId ) ),
		[]
	);

	return (
		<div
			className={ `single-doc mx-auto w-[390px] bg-white rounded-md px-6 py-3 border border-[#D1D5DB] mb-2 h-96 xs:h-64 xs:w-full xs:border-none xs:rounded-0 overflow-y-auto` }
		>
			<div className="flex items-center justify-between">
        <BackToListing />
        <ViewDoc docId={docId} />
      </div>
			<div
				className={ `doc-heading text-gray-900 font-medium text-lg mt-3 mb-1.5` }
				dangerouslySetInnerHTML={ { __html: doc?.title?.rendered } }
			></div>
			<article
				className={ 'prose break-words' }
				dangerouslySetInnerHTML={ {
					__html: doc?.content?.rendered,
				} }
			></article>
		</div>
	);
};

export default SingleDoc;
